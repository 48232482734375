// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg xmlns="http://www.w3.org/2000/svg" width="38.001" height="38.001" viewBox="0 0 38.001 38.001">
<g id="Group_6653" data-name="Group 6653" transform="translate(-368 -2869)">
  <g id="Group_6651" data-name="Group 6651">
    <ellipse id="Ellipse_359" data-name="Ellipse 359" cx="18" cy="17.5" rx="18" ry="17.5" transform="translate(369 2870)" fill="#161616"/>
  </g>
  <g id="logo_moc_token" transform="translate(362.7 2863.7)">
    <path id="Path_6092" data-name="Path 6092" d="M150.927,377.21l2.216,6.381,2.1-6.381h3.192v9.344h-2.338v-5.66l-2.069,5.66h-1.949l-2.069-5.66v5.66H147.67V377.21Z" transform="translate(-136.96 -357.777)" fill="#56beb7"/>
    <path id="Path_6093" data-name="Path 6093" d="M444.941,445.119a3.968,3.968,0,0,1,0-3.711,3.1,3.1,0,0,1,1.307-1.235,4.748,4.748,0,0,1,3.964,0,3.1,3.1,0,0,1,1.308,1.235,3.972,3.972,0,0,1,0,3.711,3.1,3.1,0,0,1-1.308,1.235,4.746,4.746,0,0,1-3.965,0A3.1,3.1,0,0,1,444.941,445.119Zm4.692-1.856a1.882,1.882,0,0,0-.374-1.221,1.375,1.375,0,0,0-2.054,0,2.183,2.183,0,0,0,0,2.443,1.375,1.375,0,0,0,2.054,0A1.9,1.9,0,0,0,449.633,443.264Z" transform="translate(-422.491 -417.931)" fill="#56beb7"/>
    <path id="Path_6094" data-name="Path 6094" d="M654.6,375.688a4.982,4.982,0,0,1,2.476-.587,4.416,4.416,0,0,1,2.029.454,3.667,3.667,0,0,1,1.428,1.261,3.824,3.824,0,0,1,.614,1.849H658.69a1.96,1.96,0,0,0-.594-1.115,1.648,1.648,0,0,0-1.154-.42,1.7,1.7,0,0,0-1.475.747,3.967,3.967,0,0,0,0,3.952,1.7,1.7,0,0,0,1.475.747,1.648,1.648,0,0,0,1.154-.42,1.96,1.96,0,0,0,.594-1.115h2.456a3.831,3.831,0,0,1-.614,1.849,3.667,3.667,0,0,1-1.428,1.261,4.4,4.4,0,0,1-2.029.456,4.982,4.982,0,0,1-2.476-.588,3.978,3.978,0,0,1-1.609-1.655,5.892,5.892,0,0,1,0-5.016A3.978,3.978,0,0,1,654.6,375.688Z" transform="translate(-622.539 -355.747)" fill="#56beb7"/>
    <path id="Path_6095" data-name="Path 6095" d="M24.3,7.652A16.644,16.644,0,1,1,17.822,8.96,16.653,16.653,0,0,1,24.3,7.652m0-2.352a19,19,0,1,0,19,19A19,19,0,0,0,24.3,5.3Z" transform="translate(0 0)" fill="#56beb7"/>
  </g>
</g>
</svg>`;
