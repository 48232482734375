var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import utf8 from 'utf8';
import { splitSignature, verifyTypedData } from 'ethers/lib/utils';
import { getRskChainId } from '../chain';
const MAX_INT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
const NONCES_FN = '0x7ecebe00';
const NAME_FN = '0x06fdde03';
const zeros = (numZeros) => ''.padEnd(numZeros, '0');
export const hexToUtf8 = (hex) => {
    let str = '';
    let code = 0;
    hex = hex.replace(/^0x/i, '');
    // remove 00 padding from either side
    hex = hex.replace(/^(?:00)*/, '');
    hex = hex.split('').reverse().join('');
    hex = hex.replace(/^(?:00)*/, '');
    hex = hex.split('').reverse().join('');
    let l = hex.length;
    for (let i = 0; i < l; i += 2) {
        code = parseInt(hex.substr(i, 2), 16);
        str += String.fromCharCode(code);
    }
    return utf8.decode(str);
};
export const signERC2612Permit = (signer, tokenAddress, owner, spender, value = MAX_INT, deadline, nonce) => __awaiter(void 0, void 0, void 0, function* () {
    const name = yield signer
        .call({ to: tokenAddress, data: NAME_FN })
        .then((res) => res.substr(130))
        .then(hexToUtf8);
    const typedData = {
        types: {
            EIP712Domain: [
                { name: 'name', type: 'string' },
                { name: 'version', type: 'string' },
                { name: 'chainId', type: 'uint256' },
                { name: 'verifyingContract', type: 'address' },
            ],
            Permit: [
                { name: 'owner', type: 'address' },
                { name: 'spender', type: 'address' },
                { name: 'value', type: 'uint256' },
                { name: 'nonce', type: 'uint256' },
                { name: 'deadline', type: 'uint256' },
            ],
        },
        primaryType: 'Permit',
        domain: {
            name,
            version: '1',
            chainId: Number(getRskChainId()),
            verifyingContract: tokenAddress,
        },
        message: {
            owner,
            spender,
            value,
            nonce: nonce === undefined
                ? yield signer.call({
                    to: tokenAddress,
                    data: `${NONCES_FN}${zeros(24)}${owner.substr(2)}`,
                })
                : nonce,
            deadline: deadline || MAX_INT,
        },
    };
    const _a = typedData.types, { EIP712Domain } = _a, types = __rest(_a, ["EIP712Domain"]);
    const signature = yield signer._signTypedData(typedData.domain, types, typedData.message);
    const { r, s, v } = splitSignature(signature);
    const verified = verifyTypedData(typedData.domain, types, typedData.message, signature);
    if (verified !== owner) {
        throw new Error('Signature verification failed');
    }
    return Object.assign({ r, s, v }, typedData.message);
});
