var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useState } from 'react';
import { t } from 'i18next';
import { Button, ButtonStyle, Icon, IconNames } from '@sovryn/ui';
import { translations } from '../../../locales/i18n';
import { createCSV, downloadCSV } from './utils';
export const ExportCSV = ({ className, filename, getData, onExportEnd, disabled = false, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const data = yield getData();
            const csvData = createCSV(data);
            downloadCSV(csvData, filename);
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
            onExportEnd === null || onExportEnd === void 0 ? void 0 : onExportEnd();
        }
    }), [filename, getData, onExportEnd]);
    return (React.createElement(Button, { text: React.createElement(React.Fragment, null,
            t(translations.exportCSV),
            React.createElement(Icon, { className: "ml-2", size: 12, icon: IconNames.LINK, viewBox: "0 0 12 12" })), onClick: onSubmit, loading: isLoading, style: ButtonStyle.secondary, className: className, disabled: disabled }));
};
