import React, { createContext, useContext, useState } from 'react';
const TransactionContext = createContext(null);
export const useTransactionContext = () => {
    return useContext(TransactionContext);
};
export const TransactionProvider = ({ children }) => {
    const [transactions, setTransactions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    return (React.createElement(TransactionContext.Provider, { value: {
            transactions,
            setTransactions,
            isOpen,
            setIsOpen,
            title,
            setTitle,
        } }, children));
};
