import setup, { ChainIds } from '@sovryn/ethers-provider';
import { RSK_EXPLORER, RSK_RPC } from '../constants/infrastructure';
import { Environments } from '../types/global';
import { isMainnet } from '../utils/helpers';
export var Chains;
(function (Chains) {
    Chains["RSK"] = "rsk";
    Chains["BSC"] = "bsc";
})(Chains || (Chains = {}));
export const defaultChainId = (isMainnet() ? ChainIds.RSK_MAINNET : ChainIds.RSK_TESTNET);
// @dev: temp solution for hardware wallets to connect to the correct chain
// good enough for now, but should be refactored when cross-chain support is needed
export const chains = [
    isMainnet()
        ? {
            id: ChainIds.RSK_MAINNET,
            label: 'Rootstock',
            token: 'RBTC',
            rpcUrl: RSK_RPC[Environments.Mainnet],
            blockExplorerUrl: RSK_EXPLORER[Environments.Mainnet],
        }
        : {
            id: ChainIds.RSK_TESTNET,
            label: 'Rootstock testnet',
            token: 'tRBTC',
            rpcUrl: RSK_RPC[Environments.Testnet],
            blockExplorerUrl: RSK_EXPLORER[Environments.Testnet],
        },
];
setup(chains);
