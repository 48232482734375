import React, { useCallback, useEffect, useMemo } from 'react';
import { t } from 'i18next';
import { Paragraph, NotificationType, Button, ButtonStyle } from '@sovryn/ui';
import { chains, defaultChainId } from '../config/chains';
import { useNotificationContext } from '../contexts/NotificationContext';
import { useWalletConnect } from './useWalletConnect';
const defaultChain = chains.find(chain => chain.id === defaultChainId);
const WrongNetworkSwitcherId = 'WrongNetworkSwitcher';
export const useWrongNetworkCheck = () => {
    const { addNotification, removeNotification } = useNotificationContext();
    const { wallets, switchNetwork } = useWalletConnect();
    const isWrongChain = useMemo(() => {
        var _a, _b;
        return (((_b = (_a = wallets[0]) === null || _a === void 0 ? void 0 : _a.accounts[0]) === null || _b === void 0 ? void 0 : _b.address) &&
            wallets[0].chains[0].id !== defaultChainId);
    }, [wallets]);
    const switchChain = useCallback(() => {
        switchNetwork(defaultChainId);
    }, [switchNetwork]);
    useEffect(() => {
        if (isWrongChain) {
            addNotification({
                type: NotificationType.warning,
                title: t('wrongNetworkSwitcher.title'),
                content: (React.createElement(React.Fragment, null,
                    React.createElement(Paragraph, null, t('wrongNetworkSwitcher.description', {
                        network: defaultChain === null || defaultChain === void 0 ? void 0 : defaultChain.label,
                    })),
                    React.createElement(Button, { className: "mb-2 mt-7", style: ButtonStyle.secondary, text: t('common.buttons.switch'), onClick: switchChain }))),
                dismissible: false,
                id: WrongNetworkSwitcherId,
            }, 0);
        }
        else {
            removeNotification(WrongNetworkSwitcherId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWrongChain]);
};
