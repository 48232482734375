import { useEffect, useMemo, useState } from 'react';
import { getProvider } from '@sovryn/ethers-provider';
import { observeCall, startCall, } from '../store/rxjs/provider-cache';
import { getRskChainId } from '../utils/chain';
import { useIsMounted } from './useIsMounted';
const DEFAULT_BLOCK_TTL = 30 * 1000; // 30 seconds
export const useBlockNumber = (chainId = getRskChainId(), options = {
    ttl: DEFAULT_BLOCK_TTL,
    fallbackToPreviousResult: true,
}) => {
    const isMounted = useIsMounted();
    const [state, setState] = useState({
        value: 0,
        loading: false,
        error: null,
    });
    useEffect(() => {
        if (!isMounted()) {
            return;
        }
        const sub = observeCall(`${chainId}_blockNumber`).subscribe(e => setState(e.result));
        startCall(`${chainId}_blockNumber`, () => getProvider(chainId).getBlockNumber(), options);
        return () => {
            sub.unsubscribe();
        };
    }, [chainId, isMounted, options]);
    return useMemo(() => (Object.assign(Object.assign({}, state), { value: state.value === null ? 0 : state.value })), [state]);
};
