import React from 'react';
import { t } from 'i18next';
import { Heading, HeadingType, Lottie, Paragraph, ParagraphStyle, } from '@sovryn/ui';
import { translations } from '../../../locales/i18n';
export const ErrorRenderer = ({ title, description, error, animation, }) => (React.createElement("div", { className: "container flex flex-col justify-center items-center flex-grow py-12" },
    React.createElement("div", { className: "text-center whitespace-normal px-4" },
        React.createElement("div", { className: "w-full flex items-center justify-center" },
            React.createElement("div", { className: "w-64" },
                React.createElement(Lottie, { animation: animation || 'error' }))),
        React.createElement(Heading, { type: HeadingType.h1, className: "font-medium text-2xl mb-10 lg:mb-12 text-center" }, title !== null && title !== void 0 ? title : t(translations.errorPage.title)),
        React.createElement(Paragraph, { className: "max-w-xl font-normal text-sm lg:text-base text-center lg:px-4", style: ParagraphStyle.tall }, description ? description : t(translations.errorPage.description)),
        error)));
