var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useAccount } from '../../../../../hooks/useAccount';
import { useBlockNumber } from '../../../../../hooks/useBlockNumber';
import { useGetProtocolContract } from '../../../../../hooks/useGetContract';
import { rskClient } from '../../../../../utils/clients';
import { useGetFastBtcDepositRskTransactionLazyQuery } from '../../../../../utils/graphql/rsk/generated';
import { defaultValue, DepositContext, DepositStep, } from '../../contexts/deposit-context';
import { useDepositSocket } from '../../hooks/useDepositSocket';
import { ReceiveEvents } from '../../types';
import { GoBackButton } from '../GoBackButton';
import { MobileCloseButton } from '../MobileCloseButton';
import { AddressForm } from './components/AddressForm';
import { MainScreen } from './components/MainScreen';
import { StatusScreen } from './components/StatusScreen';
export const ReceiveFlow = ({ onClose }) => {
    const { account } = useAccount();
    const { value: block } = useBlockNumber();
    const [state, setState] = useState(defaultValue);
    const { step, depositTx } = state;
    const [requiredSigners, setRequiredSigners] = useState();
    const fastBtcMultisigContract = useGetProtocolContract('fastBtcMultisig');
    const getRequiredSigners = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const requiredSigners = yield (fastBtcMultisigContract === null || fastBtcMultisigContract === void 0 ? void 0 : fastBtcMultisigContract.required());
        if (requiredSigners && requiredSigners > 0) {
            setRequiredSigners(requiredSigners);
        }
    }), [fastBtcMultisigContract]);
    useEffect(() => {
        getRequiredSigners().then();
    }, [getRequiredSigners]);
    const handleEvents = useCallback((type, value) => {
        switch (type) {
            case ReceiveEvents.txAmount:
                setState(prevState => (Object.assign(Object.assign({}, prevState), { limits: Object.assign(Object.assign({}, value), { loading: false }) })));
                break;
            case ReceiveEvents.depositTx:
                setState(prevState => (Object.assign(Object.assign({}, prevState), { depositTx: value, step: DepositStep.PROCESSING })));
                break;
            case ReceiveEvents.transferTx:
                setState(prevState => (Object.assign(Object.assign({}, prevState), { transferTx: value })));
                break;
        }
    }, []);
    const { ready, getDepositAddress, getTxAmount } = useDepositSocket(handleEvents);
    const handleAddressRequest = useCallback((address) => {
        setState(prevState => (Object.assign(Object.assign({}, prevState), { addressLoading: true })));
        getDepositAddress(address)
            .then(response => {
            if (requiredSigners !== undefined &&
                response.signatures.length >= requiredSigners) {
                setState(prevState => (Object.assign(Object.assign({}, prevState), { addressLoading: false, address: response.btcadr, step: DepositStep.ADDRESS, signatures: response.signatures })));
            }
            else {
                handleAddressRequest(address);
            }
        })
            .catch(error => {
            console.error(error);
            setState(prevState => (Object.assign(Object.assign({}, prevState), { addressLoading: false, address: '', addressError: error.message })));
        });
    }, [getDepositAddress, requiredSigners]);
    const value = useMemo(() => (Object.assign(Object.assign({}, state), { ready, set: setState, requestDepositAddress: handleAddressRequest })), [state, ready, setState, handleAddressRequest]);
    useEffect(() => {
        setState(prevState => (Object.assign(Object.assign({}, prevState), { limits: Object.assign(Object.assign({}, prevState.limits), { loading: true }) })));
        if (ready) {
            getTxAmount()
                .then(result => {
                setState(prevState => (Object.assign(Object.assign({}, prevState), { limits: Object.assign(Object.assign({}, result), { loading: false }) })));
            })
                .catch(() => {
                setState(prevState => (Object.assign(Object.assign({}, prevState), { limits: Object.assign(Object.assign({}, prevState.limits), { loading: false }) })));
            });
        }
    }, [ready, getTxAmount]);
    const onBackClick = useCallback(() => {
        setState(prevState => (Object.assign(Object.assign({}, prevState), { step: DepositStep.MAIN })));
    }, []);
    const [getDepositRskTransaction] = useGetFastBtcDepositRskTransactionLazyQuery();
    const getDepositRskTransactionHash = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const { data } = yield getDepositRskTransaction({
            variables: {
                bitcoinTxHash: depositTx === null || depositTx === void 0 ? void 0 : depositTx.txHash,
                user: account.toLowerCase(),
            },
            client: rskClient,
            fetchPolicy: 'network-only',
        });
        return (_b = (_a = data === null || data === void 0 ? void 0 : data.bitcoinTransfers[0]) === null || _a === void 0 ? void 0 : _a.updatedAtTx) === null || _b === void 0 ? void 0 : _b.id;
    }), [account, depositTx === null || depositTx === void 0 ? void 0 : depositTx.txHash, getDepositRskTransaction]);
    useEffect(() => {
        if (step === DepositStep.PROCESSING && (depositTx === null || depositTx === void 0 ? void 0 : depositTx.txHash)) {
            getDepositRskTransactionHash()
                .then(result => {
                if (result) {
                    setState(prevState => (Object.assign(Object.assign({}, prevState), { depositRskTransactionHash: result, step: DepositStep.COMPLETED })));
                }
            })
                .catch(console.error);
        }
    }, [depositTx === null || depositTx === void 0 ? void 0 : depositTx.txHash, getDepositRskTransactionHash, step, block]);
    return (React.createElement(DepositContext.Provider, { value: value },
        step === DepositStep.ADDRESS && React.createElement(GoBackButton, { onClick: onBackClick }),
        React.createElement("div", { className: "mt-0 md:mt-12" },
            step === DepositStep.MAIN && React.createElement(MainScreen, null),
            step === DepositStep.ADDRESS && React.createElement(AddressForm, null),
            [DepositStep.PROCESSING, DepositStep.COMPLETED].includes(step) && (React.createElement(StatusScreen, { onClose: onClose }))),
        React.createElement(MobileCloseButton, { onClick: onClose, dataAttribute: "funding-close" })));
};
