import { useEffect, useMemo, useState } from 'react';
import { ethers } from 'ethers';
import { getContract } from '@sovryn/contracts';
import { defaultChainId } from '../config/chains';
import { useAccount } from './useAccount';
import { useIsMounted } from './useIsMounted';
export const useLoadContract = (contractName, group, chain = defaultChainId, customSigner) => {
    const isMounted = useIsMounted();
    const { signer: userSigner } = useAccount();
    const [address, setAddress] = useState(undefined);
    const [abi, setAbi] = useState(undefined);
    useEffect(() => {
        getContract(contractName, group, chain).then(result => {
            if (isMounted()) {
                setAddress(result.address);
                setAbi(result.abi);
            }
        });
    }, [chain, contractName, group, isMounted]);
    return useMemo(() => {
        if (address && abi) {
            const signer = customSigner || userSigner;
            return new ethers.Contract(address, abi, signer);
        }
    }, [address, abi, customSigner, userSigner]);
};
