var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useContext, useEffect, useState } from 'react';
import { defaultChainId } from '../../../../config/chains';
import { useAccount } from '../../../../hooks/useAccount';
import { useGetProtocolContract } from '../../../../hooks/useGetContract';
import { DepositContext } from '../contexts/deposit-context';
import AddressMappingSigner from '../utils/addressMappingSignature';
export const useValidateFederators = () => {
    const { address, signatures } = useContext(DepositContext);
    const { account } = useAccount();
    const [isSignatureValid, setIsSignatureValid] = useState(false);
    const [loading, setIsLoading] = useState(true);
    const fastBtcMultisigContract = useGetProtocolContract('fastBtcMultisig');
    const validateSignatures = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!fastBtcMultisigContract) {
            return false;
        }
        const requiredFederators = yield fastBtcMultisigContract.required();
        const currentFederators = yield fastBtcMultisigContract.getOwners();
        const addressMappingSigner = new AddressMappingSigner();
        let verified = 0;
        for (let i = 0; i < signatures.length; i++) {
            let signature = signatures[i];
            const signingAddress = yield addressMappingSigner.getSigningAddress(address, account, signature.signature, defaultChainId, fastBtcMultisigContract.address);
            if (signature.signer === signingAddress) {
                for (let i = 0; i < currentFederators.length; i++) {
                    if (currentFederators[i].toLowerCase() === signingAddress) {
                        verified++;
                    }
                }
            }
        }
        return verified >= requiredFederators;
    }), [account, address, fastBtcMultisigContract, signatures]);
    useEffect(() => {
        validateSignatures().then(isValid => {
            setIsSignatureValid(isValid);
            setIsLoading(false);
        });
    }, [loading, validateSignatures]);
    return { isSignatureValid, loading };
};
