// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="38" height="38" viewBox="0 0 38 38">
  <defs>
    <clipPath id="clip-path">
      <path id="Path_2935" data-name="Path 2935" d="M710-1312.5l-1.089,1.542,1.908,2.782.26-.514,8.931-17.532Zm-1.078-3.966-3.775-.008,2.785,3.814a.54.54,0,0,0,.828,0l1.245-1.7.466-.616c.052-.052.052-.1.1-.154.313-1.235.522-2.47-.822-2.781a1.5,1.5,0,0,1,.1.463A.881.881,0,0,1,708.926-1316.468Zm-11.926-9.8,10.97,21.741a.536.536,0,0,0,.931,0l1.506-2.931-1.959-2.937Zm13.993,10.682,4.2-5.652-6.878-.015a.979.979,0,0,1-.98-.979c-2.483.561-3.055,1.795-3.473,3.8l5.068.011h.1C711.671-1318.315,711.719-1317.028,710.993-1315.589Zm-7.286-2.845c.109-2.572,1.044-4.424,4.457-4.726h.155l8.274.017,1.348-1.851a.514.514,0,0,0-.412-.824l-18.255-.037a.521.521,0,0,0-.415.822Z" transform="translate(-697 1326.271)" fill="none" clip-rule="evenodd"/>
    </clipPath>
    <clipPath id="clip-path-2">
      <path id="Path_2934" data-name="Path 2934" d="M625-1286.978h95.053V-1327H625Z" transform="translate(-625 1327)" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_6402" data-name="Group 6402" transform="translate(-310 -465.204)">
    <g id="Group_6231" data-name="Group 6231" transform="translate(310 465.667)">
      <g id="Group_6027" data-name="Group 6027" transform="translate(0 0)">
        <g id="Group_5995" data-name="Group 5995" transform="translate(0 0)">
          <g id="Group_3951" data-name="Group 3951" transform="translate(6.35 8.773)" clip-path="url(#clip-path)">
            <g id="Group_3950" data-name="Group 3950" transform="translate(-36.02 -0.365)" clip-path="url(#clip-path-2)">
              <path id="Path_2933" data-name="Path 2933" d="M692-1331.271h28.016v27.015H692Z" transform="translate(-658.482 1329.134)" fill="#fff"/>
            </g>
          </g>
          <circle id="Ellipse_357" data-name="Ellipse 357" cx="19" cy="19" r="19" transform="translate(0 -0.463)"/>
        </g>
        <g id="Group_5994" data-name="Group 5994" transform="translate(6.352 8.978)">
          <path id="Path_2977" data-name="Path 2977" d="M1185.5,847.665l-.308.416-1.166,1.566-.972,1.308a.762.762,0,0,1-1.223,0l-.929-1.247-.37-.5-1.925-2.588h4.464a.983.983,0,0,0,.985-.985.8.8,0,0,0-.011-.135.125.125,0,0,0,.027,0,1.622,1.622,0,0,1,.888.308,1.38,1.38,0,0,1,.434.873C1185.455,847,1185.474,847.335,1185.5,847.665Z" transform="translate(-1170.176 -836.52)" fill="#e9eae9"/>
          <path id="Path_2978" data-name="Path 2978" d="M1181.006,829.721a2.313,2.313,0,0,0-.055-.587,2.01,2.01,0,0,0-.1-.3,1.352,1.352,0,0,0-.175-.272,2.132,2.132,0,0,0-1.072-.648,4.205,4.205,0,0,0-1.134-.213h-.023l-.013,0v0H1172.6a7.762,7.762,0,0,1,.542-1.666,6.149,6.149,0,0,1,.963-1.441,3.805,3.805,0,0,1,1.342-.965,3.409,3.409,0,0,1,1.416-.254.728.728,0,0,0-.009.126.984.984,0,0,0,.986.985h7.7l-4.6,6.187C1180.964,830.36,1181,830.051,1181.006,829.721Z" transform="translate(-1165.639 -819.422)" fill="#e9eae9"/>
          <path id="Path_2979" data-name="Path 2979" d="M1158.705,809.227c-.075,0-.15,0-.225.007a3.988,3.988,0,0,0-1.7.525,4.266,4.266,0,0,0-1.351,1.277,6.681,6.681,0,0,0-.8,1.629,8.385,8.385,0,0,0-.387,1.75l-5.179-7.167a.764.764,0,0,1,.594-1.217h19.469a.764.764,0,0,1,.594,1.217l-1.433,1.983h-8.9C1159.2,809.231,1158.875,809.228,1158.705,809.227Z" transform="translate(-1147.137 -805.989)" fill="#e9eae9"/>
          <path id="Path_2980" data-name="Path 2980" d="M1187.209,861.852s0,0-.005,0l-.307-.411a.871.871,0,0,1-.057-.089Z" transform="translate(-1176.452 -848.688)" fill="#e9eae9"/>
          <path id="Path_2981" data-name="Path 2981" d="M1153.289,821.789l2.962,3.884-.038.07-1.469,2.749a.963.963,0,0,1-1.694-.009l-1.45-2.8-5.024-9.69-1-1.923-4.25-8.2.042-.024,6.136,8.223L1148.94,816l4.214,5.647A.872.872,0,0,0,1153.289,821.789Z" transform="translate(-1141.326 -805.852)" fill="#e9eae9"/>
          <path id="Path_2982" data-name="Path 2982" d="M1209.948,805.877l-6.173,11.832-.817,1.567-3.113,5.967-2.238-3.006a.876.876,0,0,0,.195-.192l2.07-2.769,1.172-1.567,4.966-6.644.831-1.111,3.066-4.1Z" transform="translate(-1185.056 -805.852)" fill="#e9eae9"/>
        </g>
      </g>
    </g>
  </g>
</svg>
`;
