import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Icon, IconNames, useOnClickOutside } from '@sovryn/ui';
import styles from './TableFilter.module.css';
import { Filter } from './components/Filter/Filter';
export const TableFilter = ({ filterList, onChange }) => {
    const buttonRef = useRef(null);
    const filterRef = useRef(null);
    const [show, setShow] = useState(false);
    useOnClickOutside([buttonRef, filterRef], () => setShow(false));
    const isActive = useMemo(() => show || filterList.some(f => f.checked === true), [show, filterList]);
    const toggleFilters = useCallback(() => setShow(!show), [show]);
    const onClose = useCallback(() => setShow(false), []);
    return (React.createElement("div", { ref: filterRef, className: styles.wrapper },
        React.createElement("button", { onClick: toggleFilters, className: classNames(styles.button, {
                [styles.active]: isActive,
            }), ref: buttonRef },
            React.createElement(Icon, { icon: IconNames.FUNNEL, size: 12, viewBox: "0 0 12 8" })),
        show && (React.createElement(Filter, { filterList: filterList, onClose: onClose, onChange: onChange }))));
};
