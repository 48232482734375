import { useMemo } from 'react';
import { useGetTroveQuery, } from '../../../../utils/graphql/zero/generated';
export const useGetTroves = (account, pageSize, page, filters, orderOptions) => {
    const troveConfig = useMemo(() => ({
        user: account === null || account === void 0 ? void 0 : account.toLowerCase(),
        skip: page * pageSize,
        pageSize,
        orderBy: orderOptions.orderBy || undefined,
        orderDirection: orderOptions.orderDirection || undefined,
        filters,
    }), [account, page, orderOptions, filters, pageSize]);
    return useGetTroveQuery({ variables: troveConfig });
};
