import React, { useCallback, useState } from 'react';
import { Button, ButtonStyle, Checkbox } from '@sovryn/ui';
import styles from './Filter.module.css';
export const Filter = ({ filterList, onClose, onChange }) => {
    const [filters, setFilters] = useState(filterList.map(item => (Object.assign({}, item))));
    const updateFilters = useCallback((checked, index) => {
        const newFilters = [...filters];
        newFilters[index].checked = !!checked;
        setFilters(newFilters);
    }, [filters]);
    const onSubmit = useCallback(() => {
        onChange(filters);
        onClose();
    }, [filters, onChange, onClose]);
    return (React.createElement("div", { className: styles.filter },
        React.createElement("div", null,
            filters.map((filterItem, index) => (React.createElement(Checkbox, { key: filterItem.label, label: filterItem.label, checked: filterItem.checked, onChange: e => updateFilters(e.target.checked, index) }))),
            React.createElement("div", { className: "w-full flex items-center justify-between mt-3" },
                React.createElement(Button, { style: ButtonStyle.secondary, text: "Cancel", onClick: onClose }),
                React.createElement(Button, { text: "OK", onClick: onSubmit })))));
};
