import { useMemo } from 'react';
import { constants } from 'ethers';
import { getTokenContract, } from '@sovryn/contracts';
import { Decimal } from '@sovryn/utils';
import { GAS_LIMIT } from '../constants/gasLimits';
import { getRskChainId } from '../utils/chain';
import { composeGas } from '../utils/helpers';
import { useAssetBalance } from './useAssetBalance';
import { useAsync } from './useAsync';
import { useGasPrice } from './useGasPrice';
export const useMaxAssetBalance = (asset, chainId = getRskChainId(), gasLimit = GAS_LIMIT.MAX, options) => {
    const gasPrice = useGasPrice(chainId);
    const result = useAssetBalance(asset, chainId, null, 0, options);
    const contract = useAsync(() => getTokenContract(asset, chainId));
    return useMemo(() => {
        const value = Decimal.max(result.balance.sub((contract === null || contract === void 0 ? void 0 : contract.address) === constants.AddressZero
            ? composeGas(gasPrice || '0', gasLimit)
            : 0), 0);
        return {
            weiBalance: value.toBigNumber().toString(),
            balance: value,
            bigNumberBalance: value.toBigNumber(),
            decimalPrecision: result.decimalPrecision,
            loading: false,
            error: null,
        };
    }, [
        result.balance,
        result.decimalPrecision,
        contract === null || contract === void 0 ? void 0 : contract.address,
        gasPrice,
        gasLimit,
    ]);
};
