import { useCallback, useMemo } from 'react';
import { useMaintenanceModeContext } from '../contexts/MaintenanceModeContext';
import { isStaging } from '../utils/helpers';
var States;
(function (States) {
    // D1 states left here for reference
    States["FULL"] = "full";
    States["OPEN_MARGIN_TRADES"] = "openMarginTrades";
    States["ADD_TO_MARGIN_TRADES"] = "addToMarginTrades";
    States["CLOSE_MARGIN_TRADES"] = "closeMarginTrades";
    States["MARGIN_LIMIT"] = "openMarginLimit";
    States["CLOSE_MARGIN_LIMIT"] = "closeMarginLimit";
    States["SPOT_TRADES"] = "spotTrades";
    States["SPOT_LIMIT"] = "openSpotLimit";
    States["CLOSE_SPOT_LIMIT"] = "closeSpotLimit";
    States["SWAP_TRADES"] = "swapTrades";
    States["DEPOSIT_LEND"] = "depositLend";
    States["WITHDRAW_LEND"] = "withdrawLend";
    States["START_BORROW"] = "startBorrow";
    States["STOP_BORROW"] = "stopBorrow";
    States["ADD_LIQUIDITY"] = "addLiquidity";
    States["REMOVE_LIQUIDITY"] = "removeLiquidity";
    States["TRANSAK"] = "transak";
    States["BRIDGE"] = "bridge";
    States["ETH_BRIDGE"] = "ethBridge";
    States["BSC_BRIDGE"] = "bscBridge";
    States["ETH_BRIDGE_DEPOSIT"] = "ethBridgeDeposit";
    States["ETH_BRIDGE_WITHDRAW"] = "ethBridgeWithdraw";
    States["BSC_BRIDGE_DEPOSIT"] = "bscBridgeDeposit";
    States["BSC_BRIDGE_WITHDRAW"] = "bscBridgeWithdraw";
    States["BRIDGE_SOV_DEPOSIT"] = "bridgeSOVDeposit";
    States["BRIDGE_SOV_WITHDRAW"] = "bridgeSOVWithdraw";
    States["BRIDGE_XUSD_DEPOSIT"] = "bridgeXUSDDeposit";
    States["BRIDGE_XUSD_WITHDRAW"] = "bridgeXUSDWithdraw";
    States["BRIDGE_ETH_DEPOSIT"] = "bridgeETHDeposit";
    States["BRIDGE_ETH_WITHDRAW"] = "bridgeETHWithdraw";
    States["BRIDGE_BNB_DEPOSIT"] = "bridgeBNBDeposit";
    States["BRIDGE_BNB_WITHDRAW"] = "bridgeBNBWithdraw";
    States["STAKING"] = "staking";
    States["UNSTAKING"] = "unstaking";
    States["WITHDRAW_FEES"] = "withdrawEarnedFees";
    States["DELEGATE_STAKES"] = "delegateStakes";
    States["DELEGATE_VESTS"] = "delegateVests";
    States["WITHDRAW_VESTS"] = "withdrawVests";
    States["CLAIM_REWARDS"] = "claimRewards";
    States["CLAIM_REWARD_SOV"] = "claimRewardSov";
    States["CLAIM_LIQUID_SOV"] = "claimLiquidSov";
    States["CLAIM_FEES_EARNED"] = "claimFeesEarned";
    States["PERPETUALS"] = "perpetuals";
    States["PERPETUALS_ACCOUNT_FUND"] = "perpetualsAccountFund";
    States["PERPETUALS_ACCOUNT_WITHDRAW"] = "perpetualsAccountWithdraw";
    States["PERPETUALS_ACCOUNT_TRANSFER"] = "perpetualsAccountTransfer";
    States["PERPETUALS_TRADE"] = "perpetualsTrade";
    States["PERPETUALS_GSN"] = "perpetualsGsn";
    // D2 states
    States["FULLD2"] = "fullD2";
    States["FASTBTC_SEND"] = "fastBTCSend";
    States["FASTBTC_RECEIVE"] = "fastBTCReceive";
    States["ZERO_CONVERT"] = "zeroConvert";
    States["ZERO_OPEN_LOC"] = "zeroOpenLOC";
    States["ZERO_ADJUST_LOC"] = "zeroAdjustLOC";
    States["ZERO_ADJUST_LOC_BORROW"] = "zeroAdjustLOCBorrow";
    States["ZERO_CLOSE_LOC"] = "zeroCloseLOC";
    States["ZERO_STABILITY_ADD"] = "zeroStabilityAdd";
    States["ZERO_STABILITY_REMOVE"] = "zeroStabilityRemove";
    States["ZERO_STABILITY_CLAIM"] = "zeroStabilityClaim";
    States["ZERO_REDEMPTIONS"] = "zeroRedemptions";
    States["ZERO_DLLR"] = "zeroDLLR";
    States["ZERO_EXPORT_CSV"] = "zeroExportCSV";
})(States || (States = {}));
export function useMaintenance() {
    const maintenanceStates = useMaintenanceModeContext();
    const checkMaintenance = useCallback((...names) => {
        if (process.env.REACT_APP_BYPASS_MAINTENANCE === 'true' || isStaging()) {
            return false;
        }
        return names.every(
        // assume we are in maintenance if states aren't retrieved (e.g. maintenance service unavailable)
        name => { var _a, _b; return (_b = (_a = maintenanceStates[name]) === null || _a === void 0 ? void 0 : _a.isInMaintenance) !== null && _b !== void 0 ? _b : true; });
    }, [maintenanceStates]);
    const checkMaintenances = useCallback(() => {
        return Object.keys(maintenanceStates).reduce((res, curr) => Object.assign(res, {
            [curr]: checkMaintenance(curr),
        }), {});
    }, [checkMaintenance, maintenanceStates]);
    return useMemo(() => ({ checkMaintenance, checkMaintenances, States }), [checkMaintenance, checkMaintenances]);
}
