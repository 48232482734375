var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useMemo, useState } from 'react';
import { startWith } from 'rxjs/operators';
import { connectWallet$ } from '@sovryn/onboard-core/dist/streams';
import { onboard } from '../lib/connector';
export const useWalletConnect = () => {
    const [pending, setPending] = useState(false);
    const [wallets, setWallets] = useState(onboard.state.get().wallets);
    const connectWallet = useCallback(() => {
        onboard.connectWallet();
    }, []);
    const disconnectWallet = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield onboard.disconnectWallet();
    }), []);
    const switchNetwork = useCallback((chainId) => onboard.setChain({
        chainId,
    }), []);
    useEffect(() => {
        const sub = connectWallet$
            .asObservable()
            .subscribe(({ inProgress }) => setPending(inProgress));
        return () => sub.unsubscribe();
    }, []);
    useEffect(() => {
        const sub = onboard.state
            .select('wallets')
            .pipe(startWith(onboard.state.get().wallets))
            .subscribe(setWallets);
        return () => sub.unsubscribe();
    }, []);
    const account = useMemo(() => { var _a, _b; return (_b = (_a = wallets[0]) === null || _a === void 0 ? void 0 : _a.accounts[0]) === null || _b === void 0 ? void 0 : _b.address; }, [wallets]);
    const getWallet = useCallback((index) => wallets[index], [wallets]);
    const getAccount = useCallback((index) => { var _a, _b; return (_b = (_a = wallets[index]) === null || _a === void 0 ? void 0 : _a.accounts[0]) === null || _b === void 0 ? void 0 : _b.address; }, [wallets]);
    return {
        connectWallet,
        disconnectWallet,
        wallets,
        account,
        pending,
        switchNetwork,
        getAccount,
        getWallet,
    };
};
