import { ApolloProvider } from '@apollo/client';
import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { OnboardProvider } from '@sovryn/onboard-react';
// chain config must be imported before other internal files
import './config/chains';
import { LoaderProvider } from './app/0_meta/LoaderProvider/LoaderProvider';
import { ServiceWorkerProvider } from './app/2_molecules/ServiceWorkerProvider/ServiceWorkerProvider';
import { TransactionStepDialog } from './app/3_organisms';
import { NetworkProvider } from './app/3_organisms/NetworkProvider/NetworkProvider';
import { SharedStateProvider } from './app/3_organisms/SharedStateProvider/SharedStateProvider';
import { MaintenanceModeContextProvider } from './contexts/MaintenanceModeContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { TransactionProvider } from './contexts/TransactionContext';
import './locales/dayjs';
import './locales/i18n';
import { router } from './router';
import './styles/tailwindcss/index.css';
import { rskClient } from './utils/clients';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(React.createElement(React.StrictMode, null,
    React.createElement(NetworkProvider, null,
        React.createElement(TransactionProvider, null,
            React.createElement(NotificationProvider, null,
                React.createElement(ServiceWorkerProvider, null,
                    React.createElement(ApolloProvider, { client: rskClient },
                        React.createElement(HelmetProvider, null,
                            React.createElement(MaintenanceModeContextProvider, null,
                                React.createElement(LoaderProvider, null,
                                    React.createElement(SharedStateProvider, null,
                                        React.createElement(RouterProvider, { router: router }),
                                        React.createElement(OnboardProvider, { dataAttribute: "dapp-onboard" })))))),
                    React.createElement(TransactionStepDialog, { disableFocusTrap: true })))))));
