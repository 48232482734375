import React, { useCallback, useMemo, useReducer } from 'react';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import { SupportedTokens } from '@sovryn/contracts';
import { Button, ButtonStyle, Header as UIHeader, Icon, IconNames, applyDataAttr, noop, } from '@sovryn/ui';
import { ConnectWalletButton } from '../../2_molecules';
import { NavLink } from '../../2_molecules/NavLink/NavLink';
import { SovrynLogo } from '../../2_molecules/SovrynLogo/SovrynLogo';
import { RSK_FAUCET } from '../../../constants/general';
import { useWalletConnect, useWrongNetworkCheck } from '../../../hooks';
import { useAssetBalance } from '../../../hooks/useAssetBalance';
import { translations } from '../../../locales/i18n';
import { sharedState } from '../../../store/rxjs/shared-state';
import { isMainnet, isTestnetFastBtcEnabled } from '../../../utils/helpers';
import { ProductLinks } from './components/ProductLinks/ProductLinks';
export const Header = () => {
    const [isOpen, toggle] = useReducer(v => !v, false);
    const { connectWallet, disconnectWallet, account, pending } = useWalletConnect();
    useWrongNetworkCheck();
    const { pathname } = useLocation();
    const { balance } = useAssetBalance(SupportedTokens.rbtc);
    const hasRbtcBalance = useMemo(() => Number(balance) !== 0, [balance]);
    const enableFastBtc = useMemo(() => isMainnet() || (!isMainnet() && isTestnetFastBtcEnabled()), []);
    const handleNavClick = useCallback(() => {
        if (isOpen) {
            toggle();
        }
    }, [isOpen]);
    const handleFastBtcClick = useCallback(() => sharedState.actions.openFastBtcDialog(), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(UIHeader, { dataAttribute: "dapp-header", logo: React.createElement(SovrynLogo, { dataAttribute: "header-logo", link: "/", onClick: handleNavClick }), isOpen: isOpen, menuIcon: React.createElement(Button, { text: React.createElement(Icon, { icon: isOpen ? IconNames.X_MARK : IconNames.HAMBURGER_MENU }), style: ButtonStyle.ghost, onClick: toggle, className: "text-white" }), menuItems: React.createElement("ol", { className: "flex flex-col gap-4 lg:flex-row" },
                React.createElement(NavLink, Object.assign({ to: "/", end: true, onClick: handleNavClick }, applyDataAttr('dapp-menu-borrow')), t(translations.header.nav.borrow)),
                React.createElement(NavLink, Object.assign({ to: "/earn", onClick: handleNavClick }, applyDataAttr('dapp-menu-earn')), t(translations.header.nav.earn)),
                React.createElement(NavLink, Object.assign({ to: "/convert", onClick: handleNavClick }, applyDataAttr('dapp-menu-convert')), t(translations.header.nav.convert)),
                React.createElement(ProductLinks, null)), secondaryContent: (account || pathname !== '/') && (React.createElement("div", { className: "relative" },
                React.createElement(ConnectWalletButton, { onConnect: connectWallet, onDisconnect: disconnectWallet, address: account, pending: pending, dataAttribute: "dapp-header-connect" }))), extraContent: account &&
                hasRbtcBalance && (React.createElement(Button, { text: t(translations.header.funding), style: ButtonStyle.secondary, dataAttribute: "dapp-header-funding", onClick: enableFastBtc ? handleFastBtcClick : noop, href: enableFastBtc ? '' : RSK_FAUCET, hrefExternal: true, className: "text-gray-10" })) })));
};
