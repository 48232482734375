var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import CountUp from 'react-countup';
import { Icon, IconNames, NotificationType, Tooltip, TooltipTrigger, } from '@sovryn/ui';
import { useNotificationContext } from '../../../contexts/NotificationContext';
import { translations } from '../../../locales/i18n';
import { formatValue, getDecimalPartLength, getLocaleSeparators, decimalic, } from '../../../utils/math';
const { decimal, thousand } = getLocaleSeparators();
export const AmountRenderer = ({ value, className, precision = 4, suffix = '', prefix = '', dataAttribute, isAnimated = false, useTooltip = true, showRoundingPrefix = true, }) => {
    const { addNotification } = useNotificationContext();
    const copyAddress = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        yield navigator.clipboard.writeText(String(value));
        addNotification({
            type: NotificationType.success,
            title: t(translations.copyAmount),
            content: '',
            dismissible: true,
            id: nanoid(),
        });
    }), [addNotification, value]);
    const countUpValues = useMemo(() => {
        const endValue = decimalic(value).toString();
        const [whole = '', decimals = ''] = endValue.split('.');
        const end = parseFloat((whole !== null && whole !== void 0 ? whole : 0) + '.' + (decimals !== null && decimals !== void 0 ? decimals : 0).slice(0, precision));
        return {
            end,
            decimals: getDecimalPartLength(end),
        };
    }, [precision, value]);
    const localeFormattedValue = useMemo(() => formatValue(value, precision), [value, precision]);
    const valueIsRounded = useMemo(() => getDecimalPartLength(value) > precision, [precision, value]);
    const shouldShowRoundingPrefix = useMemo(() => valueIsRounded && showRoundingPrefix, [valueIsRounded, showRoundingPrefix]);
    const shouldShowTooltip = useMemo(() => useTooltip && valueIsRounded, [useTooltip, valueIsRounded]);
    return (React.createElement(Tooltip, { content: React.createElement("span", { className: "flex items-center" },
            `${prefix} ${value} ${suffix.toUpperCase()}`,
            React.createElement("span", { className: "ml-1 cursor-pointer hover:bg-gray-20 p-1 rounded text-gray-50", onClick: copyAddress },
                React.createElement(Icon, { icon: IconNames.COPY }))), className: classNames({
            'cursor-pointer': shouldShowTooltip,
        }), disabled: !shouldShowTooltip, trigger: TooltipTrigger.click, dataAttribute: dataAttribute },
        React.createElement("span", { className: className }, isAnimated ? (React.createElement(CountUp, { start: 0, end: countUpValues.end, decimals: countUpValues.decimals, duration: 1, separator: thousand, decimal: decimal, prefix: shouldShowRoundingPrefix ? '~ ' : '', suffix: ` ${suffix.toUpperCase()}` })) : (React.createElement(React.Fragment, null,
            shouldShowRoundingPrefix ? '~ ' : '',
            prefix,
            localeFormattedValue,
            " ",
            suffix.toUpperCase())))));
};
