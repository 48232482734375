import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, DialogBody, DialogHeader, DialogSize, StatusType, } from '@sovryn/ui';
import { useNotificationContext } from '../../../contexts/NotificationContext';
import { useTransactionContext } from '../../../contexts/TransactionContext';
import { useGasPrice } from '../../../hooks/useGasPrice';
import { TransactionSteps } from './components/TransactionSteps/TransactionSteps';
import { renderNotification } from './utils';
export const TransactionStepDialog = ({ onSuccess, disableFocusTrap = true, }) => {
    const { transactions, isOpen, setIsOpen, title } = useTransactionContext();
    const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
    const [txStatus, setTxStatus] = useState(null);
    const gasPrice = useGasPrice();
    const { addNotification } = useNotificationContext();
    useEffect(() => {
        const { success, error, pending } = StatusType;
        if (!isOpen && txStatus !== pending) {
            if (txStatus === success || txStatus === error) {
                addNotification(renderNotification(txStatus));
            }
        }
        setTxStatus(null);
    }, [isOpen, txStatus, transactions, addNotification]);
    return (React.createElement(Dialog, { width: DialogSize.sm, isOpen: isOpen, onClose: onClose, disableFocusTrap: disableFocusTrap },
        React.createElement(DialogHeader, { onClose: onClose, title: title }),
        React.createElement(DialogBody, null,
            React.createElement(TransactionSteps, { transactions: transactions, onClose: onClose, onSuccess: onSuccess, gasPrice: gasPrice.toString(), onTxStatusChange: setTxStatus }))));
};
