import { createContext } from 'react';
export var DepositStep;
(function (DepositStep) {
    DepositStep[DepositStep["MAIN"] = 0] = "MAIN";
    DepositStep[DepositStep["VALIDATION"] = 1] = "VALIDATION";
    DepositStep[DepositStep["AMOUNT"] = 2] = "AMOUNT";
    DepositStep[DepositStep["ADDRESS"] = 3] = "ADDRESS";
    DepositStep[DepositStep["REVIEW"] = 4] = "REVIEW";
    DepositStep[DepositStep["CONFIRM"] = 5] = "CONFIRM";
    DepositStep[DepositStep["PROCESSING"] = 6] = "PROCESSING";
    DepositStep[DepositStep["COMPLETED"] = 7] = "COMPLETED";
})(DepositStep || (DepositStep = {}));
export const defaultValue = {
    step: DepositStep.MAIN,
    ready: false,
    address: '',
    addressLoading: false,
    addressError: null,
    depositTx: null,
    transferTx: null,
    depositRskTransactionHash: null,
    limits: {
        min: 0,
        max: 0,
        baseFee: 0,
        dynamicFee: 0,
        loading: true,
    },
    signatures: [],
    set: () => {
        throw new Error('set() has not been defined.');
    },
    requestDepositAddress: (address) => {
        throw new Error('requestDepositAddress() has not been defined.');
    },
};
export const DepositContext = createContext(defaultValue);
