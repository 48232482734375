export const GAS_LIMIT = {
    MAX: 6800000,
    OPEN_TROVE: 1100000,
    ADJUST_TROVE: 4000000,
    CLOSE_TROVE: 350000,
    CLOSE_DLLR_TROVE: 500000,
    CONVERT: 200000,
    STABILITY_POOL: 250000,
    STABILITY_POOL_INC_WITHDRAW: 490000,
    STABILITY_POOL_DLLR: 600000,
    STABILITY_POOL_DLLR_INC_WITHDRAW: 690000,
    REWARDS: 240000,
    TRANSFER_LOC: 900000,
};
