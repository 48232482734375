// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg id="XUSD" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
  <g id="Group_6400-2">
    <circle id="Ellipse_357" cx="19" cy="19" r="19" fill="#e9eae9"/>
  </g>
  <g id="Group_6460" data-name="Group 6460" transform="translate(9.028 8.646)">
    <path id="Path_6090" data-name="Path 6090" d="M50.4,54.073,45.525,47.06l-4.769,7.013H35.4l7.472-10.252L35.757,33.9h5.3L45.7,40.454,50.268,33.9h5.05l-7.064,9.742,7.523,10.431Z" transform="translate(-35.4 -33.9)"/>
  </g>
  <g id="Group_6461" data-name="Group 6461" transform="translate(11.604 9.793)">
    <path id="Path_6091" data-name="Path 6091" d="M85.736,40.768a6.7,6.7,0,0,1,2.372,2.576l1.53-2.117a7.674,7.674,0,0,0-2.6-2.372c-.179-.1-.357-.179-.51-.255L85.2,40.513A5.1,5.1,0,0,1,85.736,40.768Z" transform="translate(-75.075 -38.549)" fill="#7d7e7d"/>
    <path id="Path_6092" data-name="Path 6092" d="M50.218,93.058a6.467,6.467,0,0,1-1.683-1.122,7.626,7.626,0,0,1-1.4-1.836L45.5,92.344a9.725,9.725,0,0,0,1.4,1.5,8.257,8.257,0,0,0,1.938,1.2Z" transform="translate(-45.5 -76.915)" fill="#7d7e7d"/>
    <path id="Path_6093" data-name="Path 6093" d="M59.335,54.416a6.456,6.456,0,0,1-.842.408l1.25,1.811a5.793,5.793,0,0,0,2.4-1.887,4.484,4.484,0,0,0,.663-1.3l-4.642-6.452.025-.026c-.025,0-.051,0-.051-.025l-1.53-.332V42.353l-.995,1.428-1.046-1.5v3.851L54,46a6.6,6.6,0,0,1-1.964-.74,2.4,2.4,0,0,1-.969-.995,2.9,2.9,0,0,1-.281-1.25,2.771,2.771,0,0,1,.485-1.607,3.188,3.188,0,0,1,1.454-1.148c.1-.051.2-.077.306-.128L51.786,38.4c-.051.026-.077.026-.128.051a5.789,5.789,0,0,0-2.448,2.066,5.039,5.039,0,0,0-.51.969l4.03,5.662-.587.791c.332.1.689.2,1.1.306l1.275.281v3.162l.893-1.3,1.173,1.683V49.035l1.122.255A4.182,4.182,0,0,1,60,50.412a2.7,2.7,0,0,1-.663,4Z" transform="translate(-47.884 -38.4)" fill="#7d7e7d"/>
  </g>
</svg>
`;
