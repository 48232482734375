var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useState } from 'react';
import { constants, Contract } from 'ethers';
import { commify } from 'ethers/lib/utils';
import { getTokenDetails, SupportedTokens } from '@sovryn/contracts';
import { getProvider } from '@sovryn/ethers-provider';
import { SimpleTable, SimpleTableRow } from '@sovryn/ui';
import { useWalletConnect } from '../../../hooks';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { asyncCall, idHash } from '../../../store/rxjs/provider-cache';
import { getRskChainId } from '../../../utils/chain';
import { fromWeiFixed } from '../../../utils/math';
import styles from './WalletBalance.module.css';
const tokensToDisplay = [
    SupportedTokens.rbtc,
    SupportedTokens.zusd,
    SupportedTokens.xusd,
    SupportedTokens.dllr,
];
const getBalances = (account, chainId) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Promise.all(tokensToDisplay.map(token => getBalance(account, token, chainId)));
});
const getBalance = (account, asset, chainId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const tokenDetails = yield getTokenDetails(asset, chainId);
        const hashedArgs = idHash([
            tokenDetails.address,
            tokenDetails.address === constants.AddressZero
                ? 'nativeBalance'
                : 'balanceOf',
            account,
        ]);
        const callback = tokenDetails.address === constants.AddressZero
            ? () => getProvider(chainId)
                .getBalance(account)
                .then(result => result.toString())
            : () => new Contract(tokenDetails.address, tokenDetails.abi, getProvider(chainId))
                .balanceOf(account)
                .then(result => result.toString());
        return yield asyncCall(hashedArgs, callback, {
            ttl: 1000 * 30,
            fallbackToPreviousResult: true,
        });
    }
    catch (e) {
        return '0';
    }
});
export const WalletBalance = () => {
    const isMounted = useIsMounted();
    const { account } = useWalletConnect();
    const [balances, setBalances] = useState([]);
    useEffect(() => {
        if (!isMounted() && account) {
            return;
        }
        getBalances(account, getRskChainId())
            .then(setBalances)
            .catch(() => setBalances([]));
    }, [account, isMounted]);
    const tokenOptions = useMemo(() => tokensToDisplay.map(token => (React.createElement(SimpleTableRow, { className: styles.row, key: token, label: token, value: commify(fromWeiFixed(balances[tokensToDisplay.indexOf(token)] || '0', 6)) }))), [balances]);
    return (React.createElement(SimpleTable, { children: tokenOptions, className: styles.walletBalance }));
};
