import React from 'react';
import classNames from 'classnames';
import { Outlet } from 'react-router-dom';
import { applyDataAttr, Header } from '@sovryn/ui';
import { SovrynLogo } from '../../2_molecules';
import { Footer } from '../../3_organisms';
export const EmailVerificationStateContainer = ({ className, contentClassName, dataAttribute }) => (React.createElement("div", Object.assign({ className: classNames('flex flex-col flex-grow', className) }, applyDataAttr(dataAttribute)),
    React.createElement(React.Fragment, null,
        React.createElement(Header, { logo: React.createElement(SovrynLogo, { dataAttribute: "header-logo", link: "/" }), menuIcon: React.createElement(SovrynLogo, { dataAttribute: "header-logo", link: "/" }) }),
        React.createElement("div", { className: classNames('flex-grow', contentClassName) },
            React.createElement(Outlet, null))),
    React.createElement("div", { className: "shrink" },
        React.createElement(Footer, { showDashboardLink: true }))));
