export var TransactionType;
(function (TransactionType) {
    TransactionType["signMessage"] = "sign";
    TransactionType["signTypedData"] = "signTypedData";
    TransactionType["signTransaction"] = "signTransaction";
    TransactionType["signPermit"] = "signPermit";
})(TransactionType || (TransactionType = {}));
export var TransactionReceiptStatus;
(function (TransactionReceiptStatus) {
    TransactionReceiptStatus["pending"] = "pending";
    TransactionReceiptStatus["success"] = "success";
    TransactionReceiptStatus["error"] = "error";
})(TransactionReceiptStatus || (TransactionReceiptStatus = {}));
