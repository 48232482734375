import { AlertGroupToNotificationsMapping, } from './EmailNotificationSettingsDialog.types';
export const isSubscribedToGroup = (group, subscriptions) => {
    if (subscriptions.length === 0) {
        return false;
    }
    const groupNotifications = subscriptions
        .map(item => AlertGroupToNotificationsMapping[group].includes(item.notification)
        ? item
        : null)
        .filter(item => item !== null);
    if (groupNotifications.length === 0) {
        return false;
    }
    return groupNotifications.every(item => item === null || item === void 0 ? void 0 : item.isSubscribed);
};
