var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ethers } from 'ethers';
import { TypedDataUtils } from 'ethers-eip712';
const MESSAGE_TYPES = {
    EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' },
    ],
    DepositAddressMapping: [
        { name: 'btcDepositAddress', type: 'string' },
        { name: 'rskTargetAddress', type: 'address' },
    ],
};
export default class AddressMappingSigner {
    createTemplateForMapping(btcAddress, web3Address, chainId, multisigAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            return {
                types: MESSAGE_TYPES,
                primaryType: 'DepositAddressMapping',
                domain: {
                    name: 'Sovryn FastBTC Bridge',
                    version: '1',
                    chainId: chainId,
                    verifyingContract: multisigAddress.toLowerCase(),
                },
                message: {
                    btcDepositAddress: btcAddress,
                    rskTargetAddress: web3Address.toLowerCase(),
                },
            };
        });
    }
    getSigningAddress(btcAddress, web3Address, signature, chainId, multisigAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            signature = ethers.utils.splitSignature(signature);
            const message = yield this.createTemplateForMapping(btcAddress, web3Address, chainId, multisigAddress);
            const digest = TypedDataUtils.encodeDigest(message);
            return ethers.utils.recoverAddress(digest, signature).toLowerCase();
        });
    }
}
