import React from 'react';
import classNames from 'classnames';
import { Paragraph, ParagraphSize } from '@sovryn/ui';
import { ReactComponent as ExitApp } from '../../../../../assets/images/exit-app-icon.svg';
import styles from './ProductLink.module.css';
export const ProductLink = ({ label, description, href, }) => (React.createElement("li", { className: "pl-4 lg:pl-0" },
    React.createElement("a", { href: href, target: "_blank", rel: "noopener noreferrer", className: classNames(styles.link, 'flex flex-col') },
        React.createElement(Paragraph, { className: "lg:font-bold lg:mb-1 text-sm" }, label),
        React.createElement("div", { className: "w-full justify-between items-top gap-x-2 hidden lg:flex" },
            React.createElement(Paragraph, { size: ParagraphSize.small }, description),
            React.createElement(ExitApp, { className: "w-4 h-4 flex-grow-0 flex-shrink-0 bg-sovryn-black" })))));
