// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 125 125">
  <g id="Group_6340" data-name="Group 6340" transform="translate(2895 -3836)">
    <g id="Group_6339" data-name="Group 6339" transform="translate(-2795.068 2870.063)">
      <path id="Path_6213-4" data-name="Path 6213-4" d="M4254.53,918.245a62.5,62.5,0,1,1-62.5,62.5h0A62.5,62.5,0,0,1,4254.53,918.245Z" transform="translate(-4291.962 47.692)"/>
    </g>
    <g id="Group_6336" data-name="Group 6336" transform="translate(-4059.415 3408.615)">
      <path id="Path_7209" data-name="Path 7209" d="M1227.415,447.51a42.875,42.875,0,1,0,42.875,42.875A42.876,42.876,0,0,0,1227.415,447.51Zm0,72.445a29.57,29.57,0,1,1,29.57-29.57A29.571,29.571,0,0,1,1227.411,519.955Z" transform="translate(-0.066 -0.066)" fill="#e9eae9"/>
      <path id="Path_7209_-_Outline" data-name="Path 7209 - Outline" d="M1227.349,447.385a43.261,43.261,0,0,1,8.653.872,42.7,42.7,0,0,1,15.352,6.46,43.055,43.055,0,0,1,15.555,18.889,42.707,42.707,0,0,1,2.5,8.059,43.36,43.36,0,0,1,0,17.306,42.7,42.7,0,0,1-6.46,15.352,43.054,43.054,0,0,1-18.889,15.555,42.721,42.721,0,0,1-8.059,2.5,43.35,43.35,0,0,1-17.3,0,42.7,42.7,0,0,1-15.352-6.46,43.061,43.061,0,0,1-15.556-18.889,42.711,42.711,0,0,1-2.5-8.059,43.348,43.348,0,0,1,0-17.306,42.7,42.7,0,0,1,6.461-15.352,43.061,43.061,0,0,1,18.89-15.555,42.72,42.72,0,0,1,8.059-2.5A43.245,43.245,0,0,1,1227.349,447.385Zm0,85.749a43.143,43.143,0,0,0,8.629-.87,42.582,42.582,0,0,0,15.31-6.442,42.937,42.937,0,0,0,15.512-18.837,42.6,42.6,0,0,0,2.495-8.037,43.244,43.244,0,0,0,0-17.259,42.581,42.581,0,0,0-6.442-15.31,42.936,42.936,0,0,0-18.838-15.512,42.594,42.594,0,0,0-8.037-2.495,43.234,43.234,0,0,0-17.257,0,42.583,42.583,0,0,0-15.309,6.442,42.944,42.944,0,0,0-15.513,18.837,42.592,42.592,0,0,0-2.5,8.037,43.238,43.238,0,0,0,0,17.259,42.58,42.58,0,0,0,6.443,15.31,42.943,42.943,0,0,0,18.838,15.512,42.6,42.6,0,0,0,8.036,2.495A43.132,43.132,0,0,0,1227.349,533.134Zm0-72.445a29.49,29.49,0,0,1,16.566,5.06,29.72,29.72,0,0,1,10.735,13.036,29.588,29.588,0,0,1-2.732,28.1,29.716,29.716,0,0,1-13.036,10.735,29.59,29.59,0,0,1-28.1-2.732,29.716,29.716,0,0,1-10.735-13.036,29.59,29.59,0,0,1,2.732-28.1,29.718,29.718,0,0,1,13.036-10.735A29.443,29.443,0,0,1,1227.344,460.689Zm0,59.141a29.372,29.372,0,0,0,16.5-5.04,29.6,29.6,0,0,0,10.693-12.984,29.471,29.471,0,0,0-2.721-27.987,29.6,29.6,0,0,0-12.984-10.692,29.472,29.472,0,0,0-27.987,2.721,29.6,29.6,0,0,0-10.692,12.984,29.471,29.471,0,0,0,2.721,27.987,29.6,29.6,0,0,0,12.984,10.692A29.325,29.325,0,0,0,1227.344,519.83Z" transform="translate(0)" fill="#e9eae9"/>
      <path id="Line_22" data-name="Line 22" d="M36.17-.757H0V-13.5H36.17Z" transform="translate(1208.127 483.242)" fill="#e9eae9"/>
      <path id="Line_23" data-name="Line 23" d="M38.649-.757H0V-13.5H38.649Z" transform="translate(1208.292 512.494)" fill="#e9eae9"/>
      <path id="Line_24" data-name="Line 24" d="M-.618,70.7-11.025,60.415,59.44-10.895,69.847-.611Z" transform="translate(1197.827 460.207)" fill="#e9eae9"/>
    </g>
  </g>
</svg>
`;
