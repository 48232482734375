var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ethers } from 'ethers';
import { t } from 'i18next';
import { Button, Icon, IconNames, StatusType } from '@sovryn/ui';
import { APPROVAL_FUNCTION } from '../../../../../constants/general';
import { translations } from '../../../../../locales/i18n';
import { sleep } from '../../../../../utils/helpers';
import { signERC2612Permit } from '../../../../../utils/permit/permit';
import { TransactionReceiptStatus, } from '../../TransactionStepDialog.types';
import { isMessageSignatureRequest, isPermitRequest, isTransactionRequest, isTypedDataRequest, } from '../../helpers';
import { sendOrSimulateTx } from '../../utils';
import { TransactionStep } from '../TransactionStep/TransactionStep';
export const TransactionSteps = ({ transactions, onSuccess, onClose, gasPrice, onTxStatusChange, }) => {
    const [stepData, setStepData] = useState([]);
    const [step, setStep] = useState(-1);
    const [error, setError] = useState(false);
    useEffect(() => {
        const initialize = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const steps = [];
            for (let i = 0; i < transactions.length; i++) {
                const { request } = transactions[i];
                const item = {
                    transaction: transactions[i],
                    receipt: {
                        status: TransactionReceiptStatus.pending,
                        request,
                    },
                    config: {},
                };
                if (isTransactionRequest(request)) {
                    const { contract, fnName, args: requestArgs, gasLimit } = request;
                    const args = [...requestArgs];
                    if (fnName === APPROVAL_FUNCTION) {
                        args[1] = ethers.constants.MaxUint256;
                    }
                    item.config.gasLimit =
                        gasLimit !== null && gasLimit !== void 0 ? gasLimit : (yield contract.estimateGas[fnName](...args).then(gas => gas.toString()));
                    item.config.amount =
                        fnName === APPROVAL_FUNCTION ? requestArgs[1] : undefined;
                    item.config.unlimitedAmount =
                        fnName === APPROVAL_FUNCTION ? false : undefined;
                    item.config.gasPrice = (_a = request.gasPrice) !== null && _a !== void 0 ? _a : gasPrice;
                }
                steps.push(item);
            }
            setStepData(steps);
        });
        if (gasPrice) {
            initialize();
        }
    }, [gasPrice, transactions]);
    const updateConfig = useCallback((index, config) => {
        setStepData(items => {
            if (items[index]) {
                const copy = [...items];
                copy[index].config = config;
                return copy;
            }
            return items;
        });
    }, [setStepData]);
    const updateReceipt = useCallback((index, receipt) => {
        setStepData(items => {
            if (items[index]) {
                const copy = [...items];
                copy[index].receipt = receipt;
                return copy;
            }
            return items;
        });
    }, [setStepData]);
    const handleUpdates = useCallback(() => {
        setStepData(items => items.map(item => {
            if (item.transaction.updateHandler) {
                item.transaction.request = item.transaction.updateHandler(item.transaction.request, items.map(i => i.receipt));
            }
            return item;
        }));
    }, []);
    const submit = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
        try {
            let i = 0;
            if (error) {
                setError(false);
                i = step;
            }
            for (; i < transactions.length; i++) {
                setStep(i);
                const config = stepData[i].config;
                const { request } = transactions[i];
                if (isTransactionRequest(request)) {
                    const args = [...request.args];
                    if (request.fnName === APPROVAL_FUNCTION) {
                        args[1] = config.unlimitedAmount
                            ? ethers.constants.MaxUint256
                            : config.amount;
                    }
                    const tx = yield sendOrSimulateTx(request, args, config);
                    updateReceipt(i, {
                        status: TransactionReceiptStatus.pending,
                        request,
                        response: tx.hash,
                    });
                    (_b = (_a = transactions[i]).onStart) === null || _b === void 0 ? void 0 : _b.call(_a, tx.hash);
                    (_d = (_c = transactions[i]).onChangeStatus) === null || _d === void 0 ? void 0 : _d.call(_c, StatusType.pending);
                    yield tx.wait();
                    (_f = (_e = transactions[i]).onChangeStatus) === null || _f === void 0 ? void 0 : _f.call(_e, StatusType.success);
                    (_h = (_g = transactions[i]).onComplete) === null || _h === void 0 ? void 0 : _h.call(_g, tx.hash);
                    updateReceipt(i, {
                        status: TransactionReceiptStatus.success,
                        request,
                        response: tx.hash,
                    });
                    onTxStatusChange === null || onTxStatusChange === void 0 ? void 0 : onTxStatusChange(StatusType.success);
                    handleUpdates();
                }
                else if (isMessageSignatureRequest(request)) {
                    const signature = yield request.signer.signMessage(request.message);
                    (_k = (_j = transactions[i]).onChangeStatus) === null || _k === void 0 ? void 0 : _k.call(_j, StatusType.success);
                    (_m = (_l = transactions[i]).onComplete) === null || _m === void 0 ? void 0 : _m.call(_l, signature);
                    updateReceipt(i, {
                        status: TransactionReceiptStatus.success,
                        request,
                        response: signature,
                    });
                    handleUpdates();
                }
                else if (isTypedDataRequest(request)) {
                    const signature = yield request.signer._signTypedData(request.domain, request.types, request.value);
                    (_p = (_o = transactions[i]).onChangeStatus) === null || _p === void 0 ? void 0 : _p.call(_o, StatusType.success);
                    (_r = (_q = transactions[i]).onComplete) === null || _r === void 0 ? void 0 : _r.call(_q, signature);
                    updateReceipt(i, {
                        status: TransactionReceiptStatus.success,
                        request,
                        response: signature,
                    });
                    handleUpdates();
                }
                else if (isPermitRequest(request)) {
                    const response = yield signERC2612Permit(request.signer, request.token, request.owner, request.spender, request.value, request.deadline, request.nonce);
                    (_t = (_s = transactions[i]).onChangeStatus) === null || _t === void 0 ? void 0 : _t.call(_s, StatusType.success);
                    (_v = (_u = transactions[i]).onComplete) === null || _v === void 0 ? void 0 : _v.call(_u, response);
                    updateReceipt(i, {
                        status: TransactionReceiptStatus.success,
                        request,
                        response,
                    });
                    handleUpdates();
                }
                else {
                    // unknown type
                    (_x = (_w = transactions[i]).onChangeStatus) === null || _x === void 0 ? void 0 : _x.call(_w, StatusType.error);
                }
                if (i < transactions.length - 1) {
                    // allow wallet to update before next transaction
                    yield sleep(500);
                }
            }
            setStep(transactions.length);
        }
        catch (error) {
            onTxStatusChange === null || onTxStatusChange === void 0 ? void 0 : onTxStatusChange(StatusType.error);
            console.log('error:', error);
            (_z = (_y = transactions[0]).onChangeStatus) === null || _z === void 0 ? void 0 : _z.call(_y, StatusType.error);
            handleUpdates();
            setError(true);
        }
    }), [
        error,
        transactions,
        step,
        stepData,
        updateReceipt,
        handleUpdates,
        onTxStatusChange,
    ]);
    const getStatus = useCallback((i) => {
        if (i < step) {
            return StatusType.success;
        }
        if (i === step) {
            if (error) {
                return StatusType.error;
            }
            return StatusType.pending;
        }
        return StatusType.idle;
    }, [error, step]);
    const isLoading = useMemo(() => step > -1 && step < transactions.length && !error, [error, step, transactions.length]);
    useEffect(() => {
        if (transactions.length > 0 && transactions.length === step) {
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess();
        }
    }, [onSuccess, step, transactions.length]);
    const getConfig = useCallback((i) => stepData[i].config, [stepData]);
    const getReceipt = useCallback((i) => stepData[i].receipt, [stepData]);
    if (!stepData.length) {
        return (React.createElement(Icon, { size: 30, className: "animate-spin mx-auto my-4", icon: IconNames.PENDING }));
    }
    return (React.createElement("div", { className: "flex flex-col gap-4" },
        transactions.map((tx, i) => (React.createElement(TransactionStep, { key: i, transaction: tx, step: i + 1, status: getStatus(i), isLoading: isLoading, config: getConfig(i), receipt: getReceipt(i), updateConfig: (config) => updateConfig(i, config), gasPrice: gasPrice }))),
        !isLoading && transactions.length > step && (React.createElement(Button, { className: "w-full mt-7", text: t(translations.common.buttons[error ? 'retry' : 'confirm']), onClick: submit, dataAttribute: `tx-dialog-${error ? 'retry' : 'confirm'}` })),
        onClose && transactions.length === step && (React.createElement(Button, { text: t(translations.common.buttons.done), onClick: onClose, className: "w-full mt-7", dataAttribute: "tx-dialog-done" }))));
};
