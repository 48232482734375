var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { validate, getAddressInfo, AddressType, } from 'bitcoin-address-validation';
import { t } from 'i18next';
import debounce from 'lodash.debounce';
import { Button, ButtonStyle, ErrorBadge, ErrorLevel, Heading, HeadingType, Input, Paragraph, ParagraphSize, } from '@sovryn/ui';
import { useGetProtocolContract } from '../../../../../../hooks/useGetContract';
import { useMaintenance } from '../../../../../../hooks/useMaintenance';
import { translations } from '../../../../../../locales/i18n';
import { currentNetwork } from '../../../../../../utils/helpers';
import { WithdrawContext, WithdrawStep, } from '../../../contexts/withdraw-context';
var AddressValidationState;
(function (AddressValidationState) {
    AddressValidationState[AddressValidationState["NONE"] = 0] = "NONE";
    AddressValidationState[AddressValidationState["LOADING"] = 1] = "LOADING";
    AddressValidationState[AddressValidationState["VALID"] = 2] = "VALID";
    AddressValidationState[AddressValidationState["INVALID"] = 3] = "INVALID";
})(AddressValidationState || (AddressValidationState = {}));
export const AddressForm = () => {
    const { address, set } = useContext(WithdrawContext);
    const fastBtcBridgeContract = useGetProtocolContract('fastBtcBridge');
    const { checkMaintenance, States } = useMaintenance();
    const fastBtcLocked = checkMaintenance(States.FASTBTC_SEND);
    const [addressValidationState, setAddressValidationState] = useState(AddressValidationState.NONE);
    const [value, setValue] = useState(address);
    const invalid = useMemo(() => addressValidationState === AddressValidationState.INVALID, [addressValidationState]);
    const onContinueClick = useCallback(() => set(prevState => (Object.assign(Object.assign({}, prevState), { address: value, step: WithdrawStep.REVIEW }))), [set, value]);
    const validateAddress = useCallback((address) => __awaiter(void 0, void 0, void 0, function* () {
        setAddressValidationState(AddressValidationState.LOADING);
        let result = false;
        const isValidBtcAddress = validate(address);
        if (!fastBtcBridgeContract) {
            return;
        }
        const isValid = fastBtcBridgeContract.isValidBtcAddress(address);
        if (isValidBtcAddress && isValid) {
            const { network, type } = getAddressInfo(address);
            if (network.toLowerCase() === currentNetwork.toLowerCase() &&
                type.toLowerCase() !== AddressType.p2tr) {
                result = true;
            }
        }
        setAddressValidationState(result ? AddressValidationState.VALID : AddressValidationState.INVALID);
    }), [fastBtcBridgeContract]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedOnChange = useCallback(debounce(addressToValidate => validateAddress(addressToValidate), 300), [validateAddress]);
    useEffect(() => {
        if (value) {
            setAddressValidationState(AddressValidationState.NONE);
            delayedOnChange(value);
        }
    }, [delayedOnChange, value]);
    const isSubmitDisabled = useMemo(() => invalid || fastBtcLocked || !value || value === '', [fastBtcLocked, invalid, value]);
    return (React.createElement("div", { className: "text-center" },
        React.createElement(Heading, { type: HeadingType.h2, className: "font-medium mb-8" }, t(translations.fastBtc.send.addressForm.title)),
        React.createElement("div", { className: "text-left" },
            React.createElement(Paragraph, { size: ParagraphSize.base, className: "font-medium mb-3" }, t(translations.fastBtc.send.addressForm.addressLabel)),
            React.createElement(Input, { onChangeText: setValue, value: value, className: "max-w-none" })),
        React.createElement(Button, { text: t(translations.common.buttons.continue), onClick: onContinueClick, disabled: isSubmitDisabled, style: ButtonStyle.secondary, className: "mt-10 w-full", dataAttribute: "funding-send-address-confirm" }),
        fastBtcLocked && (React.createElement(ErrorBadge, { level: ErrorLevel.Warning, message: t(translations.maintenanceMode.fastBtc) }))));
};
