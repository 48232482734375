var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { t } from 'i18next';
import { getTokenDetailsByAddress, findContract, } from '@sovryn/contracts';
import { Accordion, AmountInput, Button, ButtonType, ButtonStyle, Heading, HeadingType, Paragraph, RadioButtonGroup, SimpleTable, SimpleTableRow, StatusItem, StatusType, } from '@sovryn/ui';
import { chains, defaultChainId } from '../../../../../config/chains';
import { AmountRenderer } from '../../../../2_molecules/AmountRenderer/AmountRenderer';
import { TxIdWithNotification } from '../../../../2_molecules/TxIdWithNotification/TransactionIdWithNotification';
import { BITCOIN, BTC_RENDER_PRECISION, } from '../../../../../constants/currencies';
import { APPROVAL_FUNCTION } from '../../../../../constants/general';
import { translations } from '../../../../../locales/i18n';
import { fromWei, toWei } from '../../../../../utils/math';
import { isTransactionRequest } from '../../helpers';
const chain = chains.find(chain => chain.id === defaultChainId);
export const TransactionStep = ({ step, status, transaction, config, receipt, gasPrice, updateConfig, isLoading, }) => {
    var _a, _b;
    const { request, title, subtitle } = transaction;
    const [token, setToken] = useState();
    useEffect(() => {
        if (isTransactionRequest(request)) {
            const { contract } = request;
            findContract(contract.address).then(result => {
                if (result.group === 'tokens') {
                    getTokenDetailsByAddress(contract.address)
                        .then(setToken)
                        .catch(e => {
                        console.error('token not found?', result, e);
                    });
                }
            });
        }
    }, [request]);
    const resetConfig = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (isTransactionRequest(request)) {
            try {
                const { contract, fnName, args, gasLimit: requestGasLimit, gasPrice: requestGasPrice, } = request;
                const gasLimit = requestGasLimit !== null && requestGasLimit !== void 0 ? requestGasLimit : (yield contract.estimateGas[fnName](...args).then(gas => gas.toString()));
                updateConfig({
                    unlimitedAmount: false,
                    amount: fnName === APPROVAL_FUNCTION ? args[1] : undefined,
                    gasPrice: requestGasPrice !== null && requestGasPrice !== void 0 ? requestGasPrice : gasPrice,
                    gasLimit,
                });
            }
            catch (error) {
                console.log('error', error);
            }
        }
    }), [gasPrice, request, updateConfig]);
    const parsedAmount = useMemo(() => {
        var _a;
        return (token === null || token === void 0 ? void 0 : token.decimalPrecision) && config.amount !== undefined
            ? formatUnits((_a = config.amount) === null || _a === void 0 ? void 0 : _a.toString(), token === null || token === void 0 ? void 0 : token.decimalPrecision)
            : '';
    }, [config.amount, token === null || token === void 0 ? void 0 : token.decimalPrecision]);
    const minAmount = useMemo(() => {
        if (isTransactionRequest(request)) {
            const { fnName, args } = request;
            return fnName === APPROVAL_FUNCTION
                ? formatUnits(args[1], token === null || token === void 0 ? void 0 : token.decimalPrecision)
                : '0';
        }
        return '0';
    }, [request, token === null || token === void 0 ? void 0 : token.decimalPrecision]);
    const amountOptions = useMemo(() => [
        {
            label: t(translations.transactionStep.customAmount),
            name: 'settings-' + step,
            value: 'custom_amount',
            contentToShow: (React.createElement(AmountInput, { className: "mb-3 ml-8 w-64", disabled: !!config.unlimitedAmount, label: t(translations.common.amount), min: minAmount, decimalPrecision: 18, value: parsedAmount, onChange: e => updateConfig(Object.assign(Object.assign({}, config), { amount: parseUnits(String(e.target.value), token === null || token === void 0 ? void 0 : token.decimalPrecision) })) })),
            helper: t(translations.transactionStep.customAmountTooltip),
        },
        {
            label: t(translations.transactionStep.unlimitedAmount),
            name: 'settings-' + step,
            value: 'unlimited_amount',
            helper: t(translations.transactionStep.unlimitedAmountTooltip),
        },
    ], [
        config,
        minAmount,
        parsedAmount,
        step,
        token === null || token === void 0 ? void 0 : token.decimalPrecision,
        updateConfig,
    ]);
    const [advanced, setAdvanced] = useState(false);
    const onChange = useCallback(e => {
        updateConfig(Object.assign(Object.assign({}, config), { unlimitedAmount: e.target.value === 'unlimited_amount' }));
    }, [config, updateConfig]);
    const disabledSettings = isLoading || ![StatusType.idle, StatusType.error].includes(status);
    const estimatedGasFee = useMemo(() => {
        var _a, _b;
        return config.gasLimit && config.gasPrice
            ? Number(fromWei(toWei((_a = config.gasPrice) === null || _a === void 0 ? void 0 : _a.toString())
                .mul((_b = config.gasLimit) === null || _b === void 0 ? void 0 : _b.toString())
                .div(Math.pow(10, 9)))).toFixed(8)
            : '';
    }, [config.gasLimit, config.gasPrice]);
    return (React.createElement("div", { className: "flex flex-col" },
        React.createElement(StatusItem, { content: step, label: title, status: status }),
        React.createElement("div", { className: "ml-10" },
            status === StatusType.error && (React.createElement(Paragraph, { className: "text-error-light" },
                React.createElement("span", { className: "block" }, t(translations.transactionStep.transactionFailedTitle)),
                React.createElement("span", null, t(translations.transactionStep.transactionFailedSubtitle)))),
            subtitle && status !== StatusType.error && (React.createElement(Paragraph, { className: "text-gray-30" }, subtitle)),
            isTransactionRequest(request) && (React.createElement(React.Fragment, null,
                React.createElement(SimpleTable, { className: "max-w-72 mt-3" },
                    config.amount !== undefined && (React.createElement(SimpleTableRow, { label: t(translations.common.amount), value: config.unlimitedAmount ? ('∞') : (React.createElement(AmountRenderer, { value: parsedAmount, suffix: token === null || token === void 0 ? void 0 : token.symbol })), valueClassName: classNames(isLoading || status === StatusType.success
                            ? 'text-gray-30'
                            : 'text-primary-10', 'whitespace-nowrap overflow-auto') })),
                    React.createElement(SimpleTableRow, { label: t(translations.transactionStep.estimatedGasFee), value: React.createElement(AmountRenderer, { value: estimatedGasFee, suffix: BITCOIN, precision: BTC_RENDER_PRECISION }), valueClassName: classNames(isLoading ? 'text-gray-30' : 'text-primary-10', 'whitespace-nowrap overflow-auto') }),
                    receipt.response && (React.createElement(SimpleTableRow, { label: t(translations.common.txId), value: React.createElement(TxIdWithNotification, { href: `${chain === null || chain === void 0 ? void 0 : chain.blockExplorerUrl}/tx/${receipt.response}`, value: receipt.response }) }))),
                React.createElement(Accordion, { className: "mt-4 mb-3 text-xs", label: t(translations.transactionStep.advancedSettings), open: advanced && !disabledSettings, onClick: () => setAdvanced(!advanced), disabled: disabledSettings, dataAttribute: "tx-dialog-settings" },
                    config.amount !== undefined && (React.createElement(React.Fragment, null,
                        React.createElement(RadioButtonGroup, { options: amountOptions, onChange: onChange, className: "mt-1", defaultChecked: config.unlimitedAmount ? 1 : 0 }),
                        React.createElement(Heading, { type: HeadingType.h3, className: "mb-3" }, t(translations.transactionStep.gasSettings)))),
                    React.createElement("div", { className: "mt-2 mb-4 max-w-72" },
                        React.createElement(AmountInput, { label: t(translations.transactionStep.gasLimit), className: "mb-4", min: 0, value: (_a = config.gasLimit) === null || _a === void 0 ? void 0 : _a.toString(), onChange: e => updateConfig(Object.assign(Object.assign({}, config), { gasLimit: e.target.value.replace(/[^0-9]/g, '') })), step: "0" }),
                        React.createElement(AmountInput, { label: t(translations.transactionStep.gasPrice), unit: "Gwei", min: 0, value: (_b = config.gasPrice) === null || _b === void 0 ? void 0 : _b.toString(), onChange: e => updateConfig(Object.assign(Object.assign({}, config), { gasPrice: e.target.value })), step: "any" })),
                    React.createElement(Button, { style: ButtonStyle.ghost, type: ButtonType.reset, text: t(translations.transactionStep.resetValues), onClick: resetConfig, dataAttribute: "tx-dialog-settings-reset" })))))));
};
