import { isBigNumberish } from '@ethersproject/bignumber/lib/bignumber';
import { BigNumber } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { Decimal } from '@sovryn/utils';
const DEFAULT_UNIT = 18;
const DEFAULT_DECIMALS = 6;
const DEFAULT_DECIMALS_SEPARATOR = '.';
const DEFAULT_THOUSANDS_SEPARATOR = ',';
const unitNames = ['wei', 'kwei', 'mwei', 'gwei', 'szabo', 'finney', 'ether'];
// helper function to convert any type of ethers value to wei.
export const toWei = (value, unitName = DEFAULT_UNIT) => {
    if (isBigNumberish(value) || isScientificNumber(value)) {
        if (typeof unitName === 'string') {
            const index = unitNames.indexOf(unitName);
            if (index !== -1) {
                unitName = 3 * index;
            }
        }
        if (isBigNumberish(value)) {
            return BigNumber.from(value).mul(BigNumber.from(10).pow(unitName));
        }
        else {
            //can't just reuse same logic above, as values in scientific notation are unrecognised by BigNumber
            //so need to convert to weis before passing
            return BigNumber.from(Number(value) * Math.pow(10, BigNumber.from(unitName).toNumber()));
        }
    }
    const numberIsANumber = Number(value)
        .toString()
        .replace(/[0-9.]/g, '').length === 0;
    const stringIsANumber = String(value).replace(/[0-9.]/g, '').length === 0;
    if (numberIsANumber && stringIsANumber) {
        if (Number(value) % 1 !== 0) {
            return parseUnits(String(value), unitName);
        }
        else {
            const numberish = String(value);
            const [integer, decimals] = numberish.split('.');
            const bnInteger = BigNumber.from(integer).mul(BigNumber.from(10).pow(unitName));
            const bnDecimals = BigNumber.from(decimals).mul(BigNumber.from(10).pow(parseUnitValue(unitName) - decimals.length));
            return bnDecimals.add(bnInteger);
        }
    }
    if (stringIsANumber) {
        return parseUnits(value, unitName);
    }
    throw new Error(`Invalid BigNumberish value: ${value}`);
};
export const fromWei = (value, unitName = DEFAULT_UNIT) => {
    if (isBigNumberish(value)) {
        return formatUnits(BigNumber.from(value).toString(), unitName);
    }
    throw new Error(`Invalid BigNumberish value: ${value}`);
};
export const fromWeiFixed = (value, decimals = DEFAULT_DECIMALS, unitName = DEFAULT_UNIT) => Number(fromWei(value, unitName)).toFixed(decimals);
// todo: refactor to make sure it handles really big bignumber
export const formatValue = (value, precision = 0, roundUp = false) => decimalic(value)
    .toNumber()
    .toLocaleString(navigator.language, {
    maximumFractionDigits: precision,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    roundingMode: roundUp ? 'ceil' : 'trunc', // This is an experimental feature with the default value of 'trunc', see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat for more information
});
export const formatCompactValue = (value, precision = 0) => value.toLocaleString(navigator.language, {
    maximumFractionDigits: precision,
    notation: 'compact',
});
export const parseUnitValue = (unitName) => {
    if (typeof unitName === 'string') {
        const index = unitNames.indexOf(unitName);
        if (index !== -1) {
            unitName = 3 * index;
        }
    }
    return Number(unitName);
};
export const getLocaleSeparators = () => {
    var _a, _b;
    const number = 1200.4;
    const formattedValue = new Intl.NumberFormat(navigator.language).formatToParts(number);
    return {
        decimal: ((_a = formattedValue.find(part => part.type === 'decimal')) === null || _a === void 0 ? void 0 : _a.value) ||
            DEFAULT_DECIMALS_SEPARATOR,
        thousand: ((_b = formattedValue.find(part => part.type === 'group')) === null || _b === void 0 ? void 0 : _b.value) ||
            DEFAULT_THOUSANDS_SEPARATOR,
    };
};
export const getDecimalPartLength = (value) => { var _a, _b; return ((_b = (_a = decimalic(value).toString().split('.')) === null || _a === void 0 ? void 0 : _a[1]) === null || _b === void 0 ? void 0 : _b.length) || 0; };
export const decimalic = (value) => {
    value = Decimal.from(value || 0);
    if (value.infinite || !value) {
        return Decimal.ZERO;
    }
    return value;
};
export const isScientificNumber = (value) => String(value).search(/e[-+]?/) > 0;
