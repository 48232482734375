import { useCallback, useEffect, useState } from 'react';
export const useAsync = (asyncFunction) => {
    const [value, setValue] = useState();
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback(() => asyncFunction()
        .then(setValue)
        .catch(() => setValue(undefined)), [asyncFunction]);
    useEffect(() => {
        execute();
    }, [execute]);
    return value;
};
