import { defaultChainId } from '../../config/chains';
export const FAKE_SIMULATOR_TX_DATA = {
    contracts: [],
    generated_access_list: [],
    simulation: {},
    transaction: {
        access_list: null,
        addresses: null,
        block_hash: '',
        block_number: 0,
        contract_ids: null,
        cumulative_gas_used: 0,
        decoded_input: null,
        effective_gas_price: 0,
        error_info: {
            error_message: 'Simulator is not enabled on this env.',
            address: '',
        },
        error_message: 'Simulator is not enabled on this env.',
        from: '',
        function_selector: '',
        gas: 0,
        gas_fee_cap: 0,
        gas_price: 0,
        gas_tip_cap: 0,
        gas_used: 0,
        hash: '',
        index: 0,
        input: '',
        method: '',
        network_id: defaultChainId,
        nonce: 0,
        status: true,
        timestamp: '',
        to: '',
        transaction_info: {
            block_number: 0,
            call_trace: {},
            console_logs: null,
            contract_address: '',
            contract_id: '',
            created_at: '',
            intrinsic_gas: 0,
            logs: null,
            method: null,
            parameters: null,
            raw_state_diff: null,
            refund_gas: 0,
            stack_trace: [],
            state_diff: null,
            transaction_id: '',
        },
        value: '0',
    },
};
