import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { PageContainer } from './app/4_templates';
import { EmailVerificationStateContainer } from './app/4_templates/EmailVerificationStateContainer/EmailVerificationStateContainer';
import { earnPageLoader } from './app/5_pages/EarnPage/loader';
import { EmailDuplicateVerifiedPage } from './app/5_pages/EmailDuplicateVerifiedPage/EmailDuplicateVerifiedPage';
import { EmailErrorPage } from './app/5_pages/EmailErrorPage/EmailErrorPage';
import { EmailUnsubscribedAllPage } from './app/5_pages/EmailUnsubscribedAllPage/EmailUnsubscribedAllPage';
import { EmailUnsubscribedPage } from './app/5_pages/EmailUnsubscribedPage/EmailUnsubscribedPage';
import { EmailVerifiedPage } from './app/5_pages/EmailVerifiedPage/EmailVerifiedPage';
import { ErrorPage } from './app/5_pages/ErrorPage/ErrorPage';
import { zeroPageLoader } from './app/5_pages/ZeroPage/loader';
import { loadable } from './utils/loadable';
const Zero = loadable(() => import('./app/5_pages/ZeroPage/ZeroPage'));
const EarnPage = loadable(() => import('./app/5_pages/EarnPage/EarnPage'));
const ConvertPage = loadable(() => import('./app/5_pages/ConvertPage/ConvertPage'));
const HistoryPage = loadable(() => import('./app/5_pages/HistoryPage/HistoryPage'));
const PrivacyPolicy = loadable(() => import('./app/5_pages/PrivacyPolicy/PrivacyPolicy'));
const TermsOfUse = loadable(() => import('./app/5_pages/TermsOfUse/TermsOfUse'));
const RewardsPage = loadable(() => import('./app/5_pages/RewardsPage/RewardsPage'));
export const router = createBrowserRouter([
    {
        path: '/',
        element: (React.createElement(PageContainer, { className: "flex flex-col", contentClassName: "justify-center" })),
        errorElement: React.createElement(ErrorPage, null),
        children: [
            {
                element: React.createElement(Zero, null),
                index: true,
                loader: zeroPageLoader,
            },
            {
                path: '/borrow',
                element: React.createElement(Zero, null),
                loader: zeroPageLoader,
            },
            {
                path: '/earn',
                element: React.createElement(EarnPage, null),
                loader: earnPageLoader,
            },
            {
                path: '/convert',
                element: React.createElement(ConvertPage, null),
            },
            {
                path: '/history',
                element: React.createElement(HistoryPage, null),
            },
            {
                path: '/rewards',
                element: React.createElement(RewardsPage, null),
                loader: zeroPageLoader,
            },
        ],
    },
    {
        element: React.createElement(PageContainer, { contentClassName: "container" }),
        children: [
            {
                path: '/policies/terms-of-service',
                element: React.createElement(TermsOfUse, null),
            },
            {
                path: '/policies/privacy-policy',
                element: React.createElement(PrivacyPolicy, null),
            },
        ],
    },
    {
        element: React.createElement(EmailVerificationStateContainer, { contentClassName: "container" }),
        children: [
            {
                path: '/notifications/error',
                element: React.createElement(EmailErrorPage, null),
            },
            {
                path: '/notifications/verified',
                element: React.createElement(EmailVerifiedPage, null),
            },
            {
                path: '/notifications/duplicate',
                element: React.createElement(EmailDuplicateVerifiedPage, null),
            },
            {
                path: '/notifications/unsubscribed',
                element: React.createElement(EmailUnsubscribedPage, null),
            },
            {
                path: '/notifications/unsubscribed-all',
                element: React.createElement(EmailUnsubscribedAllPage, null),
            },
        ],
    },
]);
