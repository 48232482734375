import React from 'react';
import classNames from 'classnames';
import { applyDataAttr, StatusType } from '@sovryn/ui';
import confirmIcon from '../../../assets/images/confirm-tx.svg';
import failedIcon from '../../../assets/images/failed-tx.svg';
import pendingIcon from '../../../assets/images/pending-tx.svg';
const getStatusImage = (status) => {
    switch (status) {
        case StatusType.error:
            return failedIcon;
        case StatusType.success:
            return confirmIcon;
        default:
            return pendingIcon;
    }
};
const getDataAttributeSuffix = (status) => {
    switch (status) {
        case StatusType.error:
            return 'failed';
        case StatusType.success:
            return 'confirmed';
        default:
            return 'pending';
    }
};
export const StatusIcon = ({ status, isInline = false, dataAttribute, }) => (React.createElement("div", { className: "flex justify-center" },
    React.createElement("img", Object.assign({ src: getStatusImage(status), className: classNames(isInline ? 'h-auto flex-initial' : 'h-20 w-20', {
            'animate-spin': status === StatusType.pending,
        }) }, applyDataAttr(`${dataAttribute}-${getDataAttributeSuffix(status)}`), { alt: "Status" }))));
