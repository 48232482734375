import React, { useCallback } from 'react';
import { WithdrawContext, WithdrawStep } from '../../contexts/withdraw-context';
import { useWithdrawBridgeConfig } from '../../hooks/useWithdrawBridgeConfig';
import { GoBackButton } from '../GoBackButton';
import { MobileCloseButton } from '../MobileCloseButton';
import { AddressForm } from './components/AddressForm';
import { AmountForm } from './components/AmountForm';
import { ConfirmationScreens } from './components/ConfirmationScreens';
import { MainScreen } from './components/MainScreen';
const allowedStepsToGoBackFrom = [
    WithdrawStep.AMOUNT,
    WithdrawStep.ADDRESS,
    WithdrawStep.REVIEW,
];
const getBackStep = (step) => {
    switch (step) {
        case WithdrawStep.AMOUNT:
            return WithdrawStep.MAIN;
        case WithdrawStep.ADDRESS:
            return WithdrawStep.AMOUNT;
        case WithdrawStep.REVIEW:
            return WithdrawStep.ADDRESS;
        default:
            return WithdrawStep.AMOUNT;
    }
};
export const SendFlow = ({ onClose }) => {
    const value = useWithdrawBridgeConfig();
    const { step, set } = value;
    const onBackClick = useCallback(() => {
        set(prevState => (Object.assign(Object.assign({}, prevState), { step: getBackStep(step) })));
    }, [set, step]);
    return (React.createElement(WithdrawContext.Provider, { value: value },
        allowedStepsToGoBackFrom.includes(step) && (React.createElement(GoBackButton, { onClick: onBackClick })),
        React.createElement("div", { className: "mt-0 md:mt-12" },
            step === WithdrawStep.MAIN && React.createElement(MainScreen, null),
            step === WithdrawStep.AMOUNT && React.createElement(AmountForm, null),
            step === WithdrawStep.ADDRESS && React.createElement(AddressForm, null),
            [
                WithdrawStep.REVIEW,
                WithdrawStep.CONFIRM,
                WithdrawStep.PROCESSING,
                WithdrawStep.COMPLETED,
            ].includes(step) && React.createElement(ConfirmationScreens, { onClose: onClose })),
        React.createElement(MobileCloseButton, { onClick: onClose, dataAttribute: "funding-close" })));
};
