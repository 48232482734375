import { BehaviorSubject, distinctUntilKeyChanged, map, filter, startWith, } from 'rxjs';
const INITIAL_STATE = {
    fastBtcDialog: {
        isOpen: false,
        shouldHideSend: false,
    },
};
const store = new BehaviorSubject(INITIAL_STATE);
const dispatch = (state) => store.next(state(store.getValue()));
function select(stateKey) {
    if (!stateKey)
        return store.asObservable();
    const validStateKeys = Object.keys(store.getValue());
    if (!validStateKeys.includes(String(stateKey))) {
        throw new Error(`key: ${stateKey} does not exist on this store`);
    }
    return store.asObservable().pipe(startWith(store.getValue()), distinctUntilKeyChanged(stateKey), map(x => x === null || x === void 0 ? void 0 : x[stateKey]), filter(value => value !== null && value !== undefined));
}
const get = () => store.getValue();
// Actions
const openFastBtcDialog = (shouldHideSend = false) => dispatch(state => (Object.assign(Object.assign({}, state), { fastBtcDialog: {
        isOpen: true,
        shouldHideSend,
    } })));
const closeFastBtcDialog = () => dispatch(state => (Object.assign(Object.assign({}, state), { fastBtcDialog: Object.assign(Object.assign({}, state.fastBtcDialog), { isOpen: false }) })));
export const sharedState = {
    get,
    select,
    actions: {
        openFastBtcDialog,
        closeFastBtcDialog,
    },
};
