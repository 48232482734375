import { createContext } from 'react';
export var WithdrawStep;
(function (WithdrawStep) {
    WithdrawStep[WithdrawStep["MAIN"] = 0] = "MAIN";
    WithdrawStep[WithdrawStep["AMOUNT"] = 1] = "AMOUNT";
    WithdrawStep[WithdrawStep["ADDRESS"] = 2] = "ADDRESS";
    WithdrawStep[WithdrawStep["REVIEW"] = 3] = "REVIEW";
    WithdrawStep[WithdrawStep["CONFIRM"] = 4] = "CONFIRM";
    WithdrawStep[WithdrawStep["PROCESSING"] = 5] = "PROCESSING";
    WithdrawStep[WithdrawStep["COMPLETED"] = 6] = "COMPLETED";
})(WithdrawStep || (WithdrawStep = {}));
export const defaultValue = {
    step: WithdrawStep.MAIN,
    amount: '',
    address: '',
    limits: {
        min: 0,
        max: 0,
        baseFee: 0,
        dynamicFee: 0,
        loading: true,
    },
    set: () => {
        throw new Error('set() has not been defined.');
    },
};
export const WithdrawContext = createContext(defaultValue);
