import React, { createContext, useCallback, useContext, useEffect, useRef, useState, } from 'react';
import axios from 'axios';
import { getServicesConfig } from '../utils/helpers';
const initialContextValue = {};
const MaintenanceModeContext = createContext(initialContextValue);
export const useMaintenanceModeContext = () => useContext(MaintenanceModeContext);
const servicesConfig = getServicesConfig();
export const MaintenanceModeContextProvider = ({ children, }) => {
    const timeoutId = useRef();
    const [maintenanceStates, setMaintenanceStates] = useState(initialContextValue);
    const fetchMaintenanceStates = useCallback(() => axios
        .get(servicesConfig.maintenance)
        .then(result => setMaintenanceStates(parseStates(result)))
        .finally(() => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = setTimeout(fetchMaintenanceStates, 25e3);
    }), []);
    useEffect(() => {
        fetchMaintenanceStates();
        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        };
    }, [fetchMaintenanceStates]);
    return (React.createElement(MaintenanceModeContext.Provider, { value: maintenanceStates }, children));
};
const parseStates = fetchResult => {
    const result = {};
    fetchResult === null || fetchResult === void 0 ? void 0 : fetchResult.data.forEach(item => (result[item.name] = item));
    return result;
};
