var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BigNumber } from 'ethers/lib/ethers';
import { parseUnits } from 'ethers/lib/utils';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import { NotificationType, StatusType } from '@sovryn/ui';
import { translations } from '../../../locales/i18n';
import { simulateTx } from '../../../utils/simulator/simulateTx';
export const handleNotification = (type, title, content = '', className = 'text-xs') => ({
    type,
    id: nanoid(),
    title: t(title),
    content: t(content),
    dismissible: true,
    className,
});
export const renderNotification = (txStatus) => {
    const [title, subtitle] = txStatus === StatusType.success
        ? [translations.transactionStep.transactionSuccessTitle]
        : [
            translations.common.tx.failedTitle,
            translations.common.tx.failedSubtitle,
        ];
    const type = txStatus === StatusType.success
        ? NotificationType.success
        : NotificationType.error;
    return handleNotification(type, title, subtitle);
};
export const sendOrSimulateTx = (request, args, config = {}) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const gasLimit = config.gasLimit ? (_a = config.gasLimit) === null || _a === void 0 ? void 0 : _a.toString() : undefined;
    const gasPrice = config.gasPrice
        ? parseUnits(((_b = config.gasPrice) === null || _b === void 0 ? void 0 : _b.toString()) || '0', 9)
        : undefined;
    if (process.env.REACT_APP_SIMULATE_TX === 'true') {
        try {
            const { address, interface: iface, provider, signer } = request.contract;
            const chainId = (yield provider.getNetwork()).chainId;
            const input = iface.encodeFunctionData(request.fnName, args);
            const from = yield signer.getAddress();
            const tx = yield simulateTx(chainId, [
                {
                    to: address,
                    from,
                    input,
                    value: request.value ? request.value.toString() : '0',
                    gas: gasLimit ? Number(gasLimit) : 0,
                    gas_price: gasPrice ? gasPrice.toString() : '0',
                },
            ]);
            console.log('SIMULATOR RESULT:', tx);
            return wrapAsTx(tx[0]);
        }
        catch (e) {
            console.error('SIMULATOR ERROR: ', e);
            throw e;
        }
    }
    return request.contract[request.fnName](...args, {
        value: request.value,
        gasPrice,
        gasLimit,
    });
});
const wrapAsTx = (simulation) => {
    const { transaction } = simulation;
    const wait = (confirmations) => new Promise((resolve, reject) => {
        const result = {
            to: transaction.to,
            from: transaction.from,
            contractAddress: '',
            transactionIndex: 0,
            gasUsed: BigNumber.from(transaction.gas),
            logsBloom: '',
            blockHash: transaction.block_hash,
            blockNumber: transaction.block_number,
            transactionHash: transaction.hash,
            logs: [],
            confirmations: confirmations || 1,
            cumulativeGasUsed: BigNumber.from(transaction.gas_used),
            effectiveGasPrice: BigNumber.from(transaction.gas_price),
            byzantium: true,
            type: 0,
            status: transaction.status ? 1 : 0,
        };
        setTimeout(() => {
            if (transaction.status) {
                resolve(result);
            }
            else {
                console.log('-'.repeat(80));
                console.log('Simulated transaction failed', transaction.error_message);
                console.log(simulation);
                console.log('-'.repeat(80));
                reject(result);
            }
        }, 3000);
    });
    return {
        hash: transaction.hash,
        confirmations: 1,
        from: transaction.from,
        gasLimit: BigNumber.from(transaction.gas),
        gasPrice: BigNumber.from(transaction.gas_price),
        nonce: transaction.nonce,
        to: transaction.to,
        value: BigNumber.from(transaction.value === '0x' ? '0' : transaction.value),
        data: transaction.input,
        chainId: Number(transaction.network_id),
        wait,
    };
};
