var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import classNames from 'classnames';
import { NavLink as BaseNavLink } from 'react-router-dom';
import styles from './NavLink.module.css';
export const NavLink = (_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (React.createElement("li", null,
        React.createElement(BaseNavLink, Object.assign({}, props, { className: ({ isActive }) => classNames(styles.navLink, {
                [styles.active]: isActive,
            }, className) }))));
};
