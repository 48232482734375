import { useRef, useEffect, useCallback, useState } from 'react';
import { io } from 'socket.io-client';
import { currentNetwork, isDevEnvironment } from '../../../../utils/helpers';
import { endpoints } from '../config/endpoints';
import { DEPOSIT_FEE_SATS, DEPOSIT_FEE_DYNAMIC } from '../constants';
import { ReceiveEvents } from '../types';
export function useDepositSocket(eventHandler) {
    const socket = useRef();
    const [connected, setConnected] = useState(false);
    const handleInput = useCallback((type) => (value) => {
        if (eventHandler) {
            eventHandler(type, value);
        }
    }, [eventHandler]);
    useEffect(() => {
        const { origin, pathname } = new URL(endpoints[currentNetwork]);
        socket.current = io(`${origin}/`, {
            reconnectionDelayMax: 10000,
            reconnectionAttempts: isDevEnvironment() ? 1 : 5,
            path: pathname && pathname !== '/' ? pathname : '',
        });
        socket.current.on('connect', () => {
            const s = socket.current;
            setConnected((s === null || s === void 0 ? void 0 : s.connected) || false);
            s === null || s === void 0 ? void 0 : s.on(ReceiveEvents.txAmount, handleInput(ReceiveEvents.txAmount));
            s === null || s === void 0 ? void 0 : s.on(ReceiveEvents.depositTx, handleInput(ReceiveEvents.depositTx));
            s === null || s === void 0 ? void 0 : s.on(ReceiveEvents.transferTx, handleInput(ReceiveEvents.transferTx));
        });
        socket.current.on(ReceiveEvents.disconnect, () => {
            var _a;
            setConnected(((_a = socket.current) === null || _a === void 0 ? void 0 : _a.connected) || false);
        });
        return () => {
            if (socket.current) {
                const s = socket.current;
                s.off(ReceiveEvents.txAmount);
                s.off(ReceiveEvents.depositTx);
                s.off(ReceiveEvents.transferTx);
                s.disconnect();
                socket.current = undefined;
                setConnected(false);
            }
        };
    }, [handleInput]);
    const getDepositAddress = useCallback((address) => new Promise((resolve, reject) => {
        if (socket.current) {
            socket.current.emit(ReceiveEvents.getDepositAddress, address, (err, res) => {
                if (res) {
                    resolve(res);
                }
                else {
                    reject(new Error((err === null || err === void 0 ? void 0 : err.error) || err));
                }
            });
        }
        else {
            reject(new Error('socket not connected'));
        }
    }), []);
    const getDepositHistory = useCallback((address) => new Promise((resolve, reject) => {
        if (socket.current) {
            socket.current.emit(ReceiveEvents.getDepositHistory, address, res => resolve(res));
        }
        else {
            reject(new Error('socket not connected'));
        }
    }), []);
    const getTxAmount = useCallback(() => new Promise((resolve, reject) => {
        if (socket.current) {
            socket.current.emit(ReceiveEvents.txAmount, res => {
                resolve(Object.assign(Object.assign({}, res), { min: res.min < 0.0005 ? 0.0005 : res.min, baseFee: DEPOSIT_FEE_SATS, dynamicFee: DEPOSIT_FEE_DYNAMIC }));
            });
        }
        else {
            reject(new Error('socket not connected'));
        }
    }), []);
    return {
        ready: connected,
        getDepositAddress,
        getDepositHistory,
        getTxAmount,
    };
}
