var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { t } from 'i18next';
import { nanoid } from 'nanoid';
import { Button, Dialog, DialogBody, DialogHeader, DialogSize, FormGroup, Input, NotificationType, Paragraph, ParagraphStyle, } from '@sovryn/ui';
import { useNotificationContext } from '../../../contexts/NotificationContext';
import { useAccount } from '../../../hooks/useAccount';
import { translations } from '../../../locales/i18n';
import { getServicesConfig, parseJwt, signMessage, validateEmail, } from '../../../utils/helpers';
import { defaultSubscriptionsArray, } from './EmailNotificationSettingsDialog.types';
import { Subscriptions } from './components/Subscriptions';
import { EmailNotificationSettingsContextProvider, useEmailNotificationSettingsContext, } from './contexts/EmailNotificationSettingsContext';
import { useHandleSubscriptions } from './hooks/useHandleSubscriptions';
const servicesConfig = getServicesConfig();
const notificationServiceUrl = servicesConfig.notification;
const userEndpoint = `${notificationServiceUrl}user/`;
const EmailNotificationSettingsDialogComponent = ({ isOpen, onClose }) => {
    const { account, eip1193Provider: provider } = useAccount();
    const { addNotification } = useNotificationContext();
    const [notificationToken, setNotificationToken] = useState(null);
    const [notificationUser, setNotificationUser] = useState(null);
    const [notificationWallet, setNotificationWallet] = useState(null);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const { subscriptions, haveSubscriptionsBeenUpdated } = useEmailNotificationSettingsContext();
    const { resetSubscriptions, parseSubscriptionsResponse } = useHandleSubscriptions();
    const onCloseHandler = useCallback(() => {
        setEmail((notificationUser === null || notificationUser === void 0 ? void 0 : notificationUser.email) || '');
        onClose();
    }, [notificationUser === null || notificationUser === void 0 ? void 0 : notificationUser.email, onClose]);
    const isValidEmail = useMemo(() => !email || validateEmail(email), [email]);
    const isEmailInputDisabled = useMemo(() => !notificationToken || !account || loading, [notificationToken, account, loading]);
    const hasUnconfirmedEmail = useMemo(() => notificationUser &&
        email &&
        email === notificationUser.email &&
        !notificationUser.isEmailConfirmed, [email, notificationUser]);
    const resetNotification = useCallback(() => {
        setNotificationToken(null);
        setNotificationUser(null);
        setNotificationWallet(null);
        setEmail('');
        resetSubscriptions();
    }, [resetSubscriptions]);
    const areSubscriptionsDisabled = useMemo(() => !notificationToken || loading, [loading, notificationToken]);
    const isSubmitDisabled = useMemo(() => loading ||
        !notificationToken ||
        !isValidEmail ||
        (!email && !(notificationUser === null || notificationUser === void 0 ? void 0 : notificationUser.isEmailConfirmed)) ||
        (email === (notificationUser === null || notificationUser === void 0 ? void 0 : notificationUser.email) && !haveSubscriptionsBeenUpdated), [
        email,
        isValidEmail,
        loading,
        notificationToken,
        notificationUser,
        haveSubscriptionsBeenUpdated,
    ]);
    const shouldFetchToken = useMemo(() => isOpen && !notificationToken, [isOpen, notificationToken]);
    const shouldFetchUser = useMemo(() => notificationToken && isOpen, [isOpen, notificationToken]);
    const wasAccountDisconnected = useMemo(() => (notificationToken && !account) ||
        (account && notificationWallet !== account), [account, notificationToken, notificationWallet]);
    const hasUnsavedChanges = useMemo(() => {
        const { email: serverEmail, isEmailConfirmed } = notificationUser || {};
        return (haveSubscriptionsBeenUpdated ||
            (isValidEmail &&
                email !== serverEmail &&
                (email !== '' || isEmailConfirmed)));
    }, [notificationUser, isValidEmail, email, haveSubscriptionsBeenUpdated]);
    useEffect(() => {
        if (wasAccountDisconnected) {
            resetNotification();
        }
    }, [resetNotification, wasAccountDisconnected]);
    useEffect(() => {
        if (shouldFetchToken) {
            getToken();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldFetchToken]);
    useEffect(() => {
        if (shouldFetchUser) {
            getUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldFetchUser]);
    const handleAuthenticationError = useCallback(() => {
        addNotification({
            type: NotificationType.error,
            title: t(translations.emailNotificationsDialog.authErrorMessage),
            dismissible: true,
            id: nanoid(),
        });
        onClose();
    }, [addNotification, onClose]);
    const getToken = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!account) {
            return;
        }
        const timestamp = new Date();
        const message = `Login to backend on: ${timestamp}`;
        const { data: alreadyUser } = yield axios.get(`${userEndpoint}/isUser/${account}`);
        return signMessage(provider, message)
            .then(signedMessage => axios
            .post(`${userEndpoint}${alreadyUser ? 'auth' : 'register'}`, Object.assign({ signedMessage,
            message, walletAddress: account }, (alreadyUser
            ? ''
            : { subscriptions: defaultSubscriptionsArray })))
            .then(res => {
            if (res.data && res.data.token) {
                setNotificationToken(res.data.token);
                setNotificationWallet(account);
            }
        }))
            .catch(handleAuthenticationError);
    }), [account, provider, handleAuthenticationError]);
    const handleUserDataResponse = useCallback((response, showNotifications = false) => {
        response
            .then(result => {
            if (result.data) {
                const { data } = result;
                setNotificationUser(data);
                setEmail((data === null || data === void 0 ? void 0 : data.email) || '');
                parseSubscriptionsResponse(data === null || data === void 0 ? void 0 : data.subscriptions);
                if (showNotifications) {
                    addNotification({
                        type: NotificationType.success,
                        title: t(translations.emailNotificationsDialog.successMessage),
                        dismissible: true,
                        id: nanoid(),
                    });
                }
            }
        })
            .catch(() => {
            if (showNotifications) {
                addNotification({
                    type: NotificationType.error,
                    title: t(translations.emailNotificationsDialog.errorMessage),
                    dismissible: true,
                    id: nanoid(),
                });
            }
            handleAuthenticationError();
        })
            .finally(() => setLoading(false));
    }, [parseSubscriptionsResponse, addNotification, handleAuthenticationError]);
    const handleEmailDelete = useCallback(() => {
        onClose();
        resetNotification();
        addNotification({
            type: NotificationType.success,
            title: t(translations.emailNotificationsDialog.unsubscribed),
            dismissible: true,
            id: nanoid(),
        });
    }, [addNotification, onClose, resetNotification]);
    const handleUserDelete = useCallback((response) => {
        response
            .then(handleEmailDelete)
            .catch(handleAuthenticationError)
            .finally(() => setLoading(false));
    }, [handleAuthenticationError, handleEmailDelete]);
    const getUser = useCallback(() => {
        var _a;
        if (!account || !notificationToken) {
            return;
        }
        const userId = (_a = parseJwt(notificationToken)) === null || _a === void 0 ? void 0 : _a.sub;
        if (!userId) {
            return;
        }
        setLoading(true);
        const promise = axios.get(`${userEndpoint}${userId}`, {
            headers: {
                Authorization: 'bearer ' + notificationToken,
            },
        });
        handleUserDataResponse(promise);
    }, [account, handleUserDataResponse, notificationToken]);
    const updateUser = useCallback(() => {
        var _a;
        if (!account || !notificationToken) {
            return;
        }
        const userId = (_a = parseJwt(notificationToken)) === null || _a === void 0 ? void 0 : _a.sub;
        if (!userId) {
            return;
        }
        setLoading(true);
        if ((email === null || email === void 0 ? void 0 : email.length) === 0) {
            const promise = axios.delete(`${userEndpoint}${userId}`, {
                headers: {
                    Authorization: 'bearer ' + notificationToken,
                },
            });
            handleUserDelete(promise);
        }
        else {
            const promise = axios.put(`${userEndpoint}${account}`, {
                walletAddress: account,
                email: email || undefined,
                subscriptions: subscriptions || defaultSubscriptionsArray,
            }, {
                headers: {
                    Authorization: 'bearer ' + notificationToken,
                },
            });
            handleUserDataResponse(promise, true);
        }
    }, [
        account,
        email,
        handleUserDataResponse,
        handleUserDelete,
        notificationToken,
        subscriptions,
    ]);
    const errorLabel = useMemo(() => {
        if (hasUnconfirmedEmail) {
            return t(translations.emailNotificationsDialog.unconfirmedEmailWarning);
        }
        else if (!!notificationUser && !isValidEmail) {
            return t(translations.emailNotificationsDialog.invalidEmail);
        }
    }, [hasUnconfirmedEmail, notificationUser, isValidEmail]);
    return (React.createElement(Dialog, { isOpen: isOpen, width: DialogSize.sm },
        React.createElement(DialogHeader, { onClose: onCloseHandler, title: t(translations.emailNotificationsDialog.dialogTitle) }),
        React.createElement(DialogBody, { className: "p-6" },
            React.createElement("div", { className: "p-6 bg-gray-90" },
                React.createElement(Paragraph, { style: ParagraphStyle.tall }, t(translations.emailNotificationsDialog.title)),
                React.createElement(FormGroup, { className: "mt-6 mb-4", label: t(translations.emailNotificationsDialog.emailInputLabel), errorLabel: errorLabel },
                    React.createElement(Input, { value: email, onChangeText: setEmail, placeholder: t(translations.emailNotificationsDialog.emailInputPlaceholder), disabled: isEmailInputDisabled, dataAttribute: "alert-signup-email" })),
                React.createElement(Subscriptions, { isDisabled: areSubscriptionsDisabled, dataAttribute: "alert-signup-sub" })),
            React.createElement("div", { className: "mt-4 flex flex-col items-center" },
                hasUnsavedChanges && (React.createElement(Paragraph, { className: "text-error mb-2" }, t(translations.emailNotificationsDialog.unsavedChanges))),
                React.createElement(Button, { onClick: updateUser, text: t(translations.common.buttons.save), disabled: isSubmitDisabled, className: "w-full", dataAttribute: "alert-signup-save" })))));
};
export const EmailNotificationSettingsDialog = ({ isOpen, onClose }) => (React.createElement(EmailNotificationSettingsContextProvider, null,
    React.createElement(EmailNotificationSettingsDialogComponent, { isOpen: isOpen, onClose: onClose })));
