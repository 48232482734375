import React from 'react';
import { t } from 'i18next';
import { Helmet } from 'react-helmet-async';
import { Trans } from 'react-i18next';
import { Header, Link } from '@sovryn/ui';
import { ErrorRenderer } from '../../1_atoms/ErrorRenderer/ErrorRenderer';
import { SovrynLogo } from '../../2_molecules';
import { Footer } from '../../3_organisms';
import { translations } from '../../../locales/i18n';
export const NotFoundPage = () => (React.createElement(React.Fragment, null,
    React.createElement(Helmet, null,
        React.createElement("title", null, t(translations.notFoundPage.meta.title))),
    React.createElement(Header, { logo: React.createElement(SovrynLogo, { dataAttribute: "header-logo", link: "/" }), menuIcon: React.createElement(SovrynLogo, { dataAttribute: "header-logo", link: "/" }) }),
    React.createElement(ErrorRenderer, { animation: "error404", title: "", description: React.createElement(Trans, { i18nKey: translations.notFoundPage.description, components: [
                React.createElement(Link, { className: "leading-tight font-normal text-sm lg:text-base text-center", text: window.location.hostname, href: "/", openNewTab: false }),
            ] }) }),
    React.createElement("div", { className: "shrink" },
        React.createElement(Footer, { showDashboardLink: true }))));
