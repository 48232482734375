import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { applyDataAttr } from '@sovryn/ui';
import sovrynLogo from '../../../assets/images/sovryn-logo.svg';
import styles from './SovrynLogo.module.css';
export const SovrynLogo = ({ image = sovrynLogo, text, link, dataAttribute, className, onClick, }) => {
    const Logo = useMemo(() => (React.createElement("div", { className: styles.logo },
        React.createElement("img", Object.assign({ src: image, className: className, alt: text, title: text }, applyDataAttr(dataAttribute))),
        ' ',
        text)), [className, dataAttribute, image, text]);
    return (React.createElement(React.Fragment, null, link ? (React.createElement(Link, { to: link, className: styles.link, onClick: onClick }, Logo)) : (React.createElement(React.Fragment, null, Logo))));
};
