import React, { useCallback, useState } from 'react';
import { t } from 'i18next';
import { Accordion } from '@sovryn/ui';
import { translations } from '../../../../locales/i18n';
const translation = translations.fastBtc.limits;
export const Limits = ({ minimumAmount, maximumAmount, serviceFee, className, }) => {
    const [open, setOpen] = useState(true);
    const onClick = useCallback((toOpen) => setOpen(toOpen), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Accordion, { label: t(translation.title), children: React.createElement("div", { className: "bg-gray-80 border rounded border-gray-50 p-3 text-xs text-gray-30" },
                React.createElement("div", { className: "flex justify-between mb-3" },
                    React.createElement("span", null, t(translation.minimumAmount)),
                    React.createElement("span", null, minimumAmount)),
                React.createElement("div", { className: "flex justify-between mb-3" },
                    React.createElement("span", null, t(translation.maximumAmount)),
                    React.createElement("span", null, maximumAmount)),
                React.createElement("div", { className: "flex justify-between" },
                    React.createElement("span", null, t(translation.serviceFee)),
                    React.createElement("span", null, serviceFee))), className: className, open: open, onClick: onClick })));
};
