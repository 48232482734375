var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import dayjs from 'dayjs';
import { providers } from 'ethers';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '@sovryn/tailwindcss-config';
import { BTC_EXPLORER, RSK_EXPLORER, SERVICES_CONFIG, } from '../constants/infrastructure';
import { ALPHA_LINKS, BITOCRACY_LINKS, GITHUB_LINKS } from '../constants/links';
import { Environments } from '../types/global';
import { decimalic } from './math';
export const prettyTx = (text, startLength = 6, endLength = 4) => {
    const start = text.substr(0, startLength);
    const end = text.substr(-endLength);
    return `${start} ··· ${end}`;
};
export const signTypedData = (provider, domain, types, data) => __awaiter(void 0, void 0, void 0, function* () {
    // A Signer MUST always make sure, that if present, the "from" field
    //  matches the Signer, before sending or signing a transaction
    // A Signer SHOULD always wrap private information (such as a private
    //  key or mnemonic) in a function, so that console.log does not leak
    //  the data
    const signer = new providers.Web3Provider(provider);
    const signature = yield signer
        .getSigner()
        ._signTypedData(domain, types, data);
    return signature;
});
export const currentNetwork = !!process.env.REACT_APP_NETWORK
    ? process.env.REACT_APP_NETWORK
    : Environments.Mainnet;
export const isMainnet = () => process.env.REACT_APP_NETWORK === Environments.Mainnet;
export const isDevEnvironment = () => process.env.NODE_ENV === 'development';
export const isTestnetFastBtcEnabled = () => process.env.REACT_APP_ENABLE_TESTNET_FAST_BTC === 'true';
export const getServicesConfig = () => SERVICES_CONFIG[isMainnet() ? Environments.Mainnet : Environments.Testnet];
export const getRskExplorerUrl = () => RSK_EXPLORER[isMainnet() ? 'mainnet' : 'testnet'];
export const getBtcExplorerUrl = () => BTC_EXPLORER[isMainnet() ? 'mainnet' : 'testnet'];
export const getD1Url = () => isStaging()
    ? ALPHA_LINKS.STAGING
    : ALPHA_LINKS[isMainnet() ? Environments.Mainnet : Environments.Testnet];
export const getBitocracyUrl = () => BITOCRACY_LINKS[isMainnet() ? Environments.Mainnet : Environments.Testnet];
export const dateFormat = (timestamp) => {
    const stamp = dayjs.tz(Number(timestamp) * 1e3, 'UTC');
    return stamp.format(`YYYY-MM-DD HH:MM:ss +UTC`);
};
export const signMessage = (provider, message) => __awaiter(void 0, void 0, void 0, function* () {
    const signer = new providers.Web3Provider(provider);
    const signature = yield signer.getSigner().signMessage(message);
    return signature;
});
export const validateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};
export const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(''));
    return JSON.parse(jsonPayload);
};
export const valueIsDefined = (entry) => entry[1] !== undefined;
export const composeGas = (priceInGwei, limitInWei) => decimalic(priceInGwei)
    .mul(Math.pow(10, 9))
    .mul(limitInWei)
    .div(Math.pow(10, 18));
export const isMobileDevice = () => {
    var _a, _b;
    const config = resolveConfig(tailwindConfig);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const widthToCheck = (_a = config === null || config === void 0 ? void 0 : config.theme) === null || _a === void 0 ? void 0 : _a.screens.md; // value will be in format "768px"
    const screenWidth = ((_b = window === null || window === void 0 ? void 0 : window.visualViewport) === null || _b === void 0 ? void 0 : _b.width) || 0;
    return screenWidth < parseInt(widthToCheck || '0');
};
export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
export const isStaging = () => process.env.REACT_APP_STAGING === 'true';
export const areAddressesEqual = (address1, address2) => address1.toLowerCase() === address2.toLowerCase();
export const getChangelogUrl = (commit) => `${GITHUB_LINKS.DAPP}/blob/${encodeURI(commit)}/apps/frontend/CHANGELOG.md`;
export const calculateCollateralRatio = (collateral, debt, price) => decimalic(collateral).mul(price).div(debt).mul(100);
