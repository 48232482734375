var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { hashMessage } from 'ethers/lib/utils';
import { BehaviorSubject, distinctUntilKeyChanged, map, filter, firstValueFrom, } from 'rxjs';
import { getProvider } from '@sovryn/ethers-provider';
const INITIAL_STATE = {};
const DEFAULT_TTL = 1000 * 60 * 2; // 2 minutes
const TINY_TTL = 1000 * 30; // 30 seconds
const store = new BehaviorSubject(INITIAL_STATE);
const mergeOptions = (options) => {
    var _a, _b, _c, _d;
    return ({
        ttl: (_a = options.ttl) !== null && _a !== void 0 ? _a : DEFAULT_TTL,
        blockNumber: (_b = options.blockNumber) !== null && _b !== void 0 ? _b : 0,
        force: (_c = options.force) !== null && _c !== void 0 ? _c : false,
        fallbackToPreviousResult: (_d = options.fallbackToPreviousResult) !== null && _d !== void 0 ? _d : true,
    });
};
export const startCall = (id, promise, options = {}) => {
    var _a, _b, _c;
    options = mergeOptions(options);
    const state = store.getValue();
    const now = Date.now();
    if (state.hasOwnProperty(id) &&
        state[id].ttl > now &&
        (state[id].blockNumber || 0) >= (options.blockNumber || 0) &&
        !options.force) {
        return;
    }
    const promise$ = promise()
        .then(result => {
        completeCall(id, result, null);
        return result;
    })
        .catch(error => {
        completeCall(id, null, error);
        return error;
    });
    let result = {
        value: null,
        loading: true,
        error: null,
    };
    if (options.fallbackToPreviousResult) {
        const cached = state[id];
        if (cached && !((_a = cached.result) === null || _a === void 0 ? void 0 : _a.error)) {
            result = cached.result;
        }
    }
    store.next(Object.assign(Object.assign({}, state), { [id]: {
            pending: true,
            timestamp: now,
            ttl: now + ((_b = options === null || options === void 0 ? void 0 : options.ttl) !== null && _b !== void 0 ? _b : DEFAULT_TTL),
            blockNumber: (_c = options === null || options === void 0 ? void 0 : options.blockNumber) !== null && _c !== void 0 ? _c : 0,
            promise: promise$,
            result,
        } }));
};
export const completeCall = (id, result, error) => {
    const state = store.getValue();
    if (!state.hasOwnProperty(id)) {
        return;
    }
    store.next(Object.assign(Object.assign({}, state), { [id]: Object.assign(Object.assign({}, state[id]), { pending: false, result: {
                loading: false,
                value: result,
                error,
            } }) }));
};
export const observeCall = (id) => {
    return store.asObservable().pipe(distinctUntilKeyChanged(id), map(state => state[id]), filter(state => state !== undefined));
};
export const getCall = (id) => {
    const state = store.getValue();
    return state[id];
};
export const idHash = (args) => {
    const params = args.map(item => item.toString().toLowerCase());
    const json = JSON.stringify(params);
    return hashMessage(json);
};
export const asyncCall = (id, promise, options = {}) => __awaiter(void 0, void 0, void 0, function* () {
    const result$ = observeCall(id);
    startCall(id, promise, options);
    return (yield firstValueFrom(result$)).promise.then(e => {
        if (e instanceof Error) {
            throw e;
        }
        return e;
    });
});
export const getBlockNumber = (chainId = getBlockNumber()) => __awaiter(void 0, void 0, void 0, function* () {
    return asyncCall(`${chainId}_blockNumber`, () => getProvider(chainId).getBlockNumber(), { ttl: TINY_TTL });
});
