import React from 'react';
import classNames from 'classnames';
import { Outlet } from 'react-router-dom';
import { applyDataAttr } from '@sovryn/ui';
import { DappLocked } from '../../1_atoms/DappLocked/DappLocked';
import { Header, Footer } from '../../3_organisms';
import { useMaintenance } from '../../../hooks/useMaintenance';
export const PageContainer = ({ className, contentClassName, dataAttribute, }) => {
    const { checkMaintenance, States } = useMaintenance();
    const dappLocked = checkMaintenance(States.FULLD2);
    return (React.createElement("div", Object.assign({ className: classNames('flex flex-col flex-grow', className) }, applyDataAttr(dataAttribute)),
        dappLocked ? (React.createElement(DappLocked, null)) : (React.createElement(React.Fragment, null,
            React.createElement(Header, null),
            React.createElement("div", { className: classNames('my-2 px-4 flex flex-grow', contentClassName) },
                React.createElement(Outlet, null)))),
        React.createElement("div", { className: "shrink" },
            React.createElement(Footer, null))));
};
