// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
  <g id="Group_6650" data-name="Group 6650" transform="translate(-368 -2939)">
    <ellipse id="Ellipse_360" data-name="Ellipse 360" cx="18" cy="17.5" rx="18" ry="17.5" transform="translate(369 2941)" fill="#1f1f1f"/>
    <g id="icon_doc" transform="translate(368 2939)">
      <path id="Path_2919" data-name="Path 2919" d="M20.7,3.452A17.248,17.248,0,1,1,3.452,20.7,17.252,17.252,0,0,1,20.7,3.452m0-1.752a19,19,0,1,0,19,19,19,19,0,0,0-19-19Z" transform="translate(-1.7 -1.7)" fill="#00a651"/>
      <path id="Path_2920" data-name="Path 2920" d="M54.4,127.918a4.573,4.573,0,0,1-1.836,1.8,5.86,5.86,0,0,1-2.821.648H45.9V120.2h3.841a5.98,5.98,0,0,1,2.833.636,4.515,4.515,0,0,1,1.836,1.776,5.277,5.277,0,0,1,.636,2.641A5.573,5.573,0,0,1,54.4,127.918Zm-2.653-.5a3.277,3.277,0,0,0,0-4.273,3.1,3.1,0,0,0-2.232-.756H48.349v5.809h1.164A3.223,3.223,0,0,0,51.745,127.414Z" transform="translate(-40.595 -105.977)" fill="#00a651"/>
      <path id="Path_2921" data-name="Path 2921" d="M131.6,137.785a3.674,3.674,0,0,1,1.476,1.428,4.687,4.687,0,0,1,0,4.369,3.6,3.6,0,0,1-1.476,1.428,4.452,4.452,0,0,1-2.136.492,4.594,4.594,0,0,1-2.136-.492,3.629,3.629,0,0,1-1.488-1.428,4.687,4.687,0,0,1,0-4.369,3.556,3.556,0,0,1,1.488-1.428,4.484,4.484,0,0,1,2.136-.492A4.561,4.561,0,0,1,131.6,137.785Zm-3.313,2.148a2.044,2.044,0,0,0-.48,1.476,2.013,2.013,0,0,0,.48,1.464,1.588,1.588,0,0,0,1.188.5,1.544,1.544,0,0,0,1.176-.5,2.491,2.491,0,0,0,0-2.941,1.544,1.544,0,0,0-1.176-.5,1.488,1.488,0,0,0-1.188.5Z" transform="translate(-110.464 -121.018)" fill="#00a651"/>
      <path id="Path_2922" data-name="Path 2922" d="M206.154,120.432a4.661,4.661,0,0,1,1.644,2.761h-2.6a2.386,2.386,0,0,0-.888-1.1,2.491,2.491,0,0,0-1.416-.408,2.16,2.16,0,0,0-1.74.792,3.18,3.18,0,0,0-.66,2.112,3.125,3.125,0,0,0,.66,2.1,2.186,2.186,0,0,0,1.74.78,2.409,2.409,0,0,0,2.3-1.5h2.6a4.667,4.667,0,0,1-1.644,2.749,4.97,4.97,0,0,1-3.217,1.032,5.145,5.145,0,0,1-2.6-.648,4.4,4.4,0,0,1-1.728-1.836,6.21,6.21,0,0,1,0-5.377,4.551,4.551,0,0,1,1.728-1.836,5.541,5.541,0,0,1,5.821.384Z" transform="translate(-174.431 -105.273)" fill="#00a651"/>
    </g>
  </g>
</svg>`;
