import React, { useMemo } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { Button, ErrorBadge, ErrorLevel, Heading, HeadingType, } from '@sovryn/ui';
import { TxIdWithNotification } from '../../../../../2_molecules/TxIdWithNotification/TransactionIdWithNotification';
import { BITCOIN } from '../../../../../../constants/currencies';
import { useMaintenance } from '../../../../../../hooks/useMaintenance';
import { translations } from '../../../../../../locales/i18n';
import { getBtcExplorerUrl, getRskExplorerUrl, } from '../../../../../../utils/helpers';
import { formatValue } from '../../../../../../utils/math';
const translation = translations.fastBtc.send.confirmationScreens;
const rskExplorerUrl = getRskExplorerUrl();
const btcExplorerUrl = getBtcExplorerUrl();
export const ReviewScreen = ({ amount, onConfirm, feesPaid, receiveAmount, from, to, }) => {
    const { checkMaintenance, States } = useMaintenance();
    const fastBtcLocked = checkMaintenance(States.FASTBTC_SEND);
    const items = useMemo(() => [
        {
            label: t(translation.from),
            value: (React.createElement(TxIdWithNotification, { value: from, href: `${rskExplorerUrl}/address/${from}` })),
        },
        {
            label: t(translation.to),
            value: (React.createElement(TxIdWithNotification, { value: to, href: `${btcExplorerUrl}/address/${to}` })),
        },
        {
            label: t(translation.sending),
            value: (React.createElement(React.Fragment, null,
                formatValue(Number(amount), 8),
                " ",
                BITCOIN)),
        },
        {
            label: t(translation.serviceFee),
            value: (React.createElement(React.Fragment, null,
                formatValue(feesPaid, 8),
                " ",
                BITCOIN)),
        },
        {
            label: t(translation.receiving),
            value: (React.createElement(React.Fragment, null,
                formatValue(receiveAmount, 8),
                " ",
                BITCOIN)),
        },
    ], [amount, feesPaid, from, receiveAmount, to]);
    return (React.createElement("div", { className: "text-center" },
        React.createElement(Heading, { type: HeadingType.h2, className: "font-medium mb-8" }, t(translation.reviewTitle)),
        React.createElement("div", { className: "bg-gray-80 border rounded border-gray-50 p-3 text-xs text-gray-30" }, items.map(({ label, value }, index) => (React.createElement("div", { className: classNames('flex justify-between', {
                'mb-3': index !== items.length - 1,
            }), key: label },
            React.createElement("span", null,
                label,
                " "),
            React.createElement("span", null, value))))),
        React.createElement("div", { className: "mt-8" },
            React.createElement(Button, { text: t(translations.common.buttons.confirm), onClick: onConfirm, disabled: fastBtcLocked, className: "w-full", dataAttribute: "funding-send-confirm" }),
            fastBtcLocked && (React.createElement(ErrorBadge, { level: ErrorLevel.Warning, message: t(translations.maintenanceMode.fastBtc) })))));
};
