// eslint-disable-next-line import/no-anonymous-default-export
export default `<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
<g id="Group_6652" data-name="Group 6652" transform="translate(-368 -3009)">
  <ellipse id="Ellipse_361" data-name="Ellipse 361" cx="18" cy="17.5" rx="18" ry="17.5" transform="translate(369 3011)" fill="#161616"/>
  <g id="logo_bpro" transform="translate(368 3009)">
    <ellipse id="Ellipse_14" data-name="Ellipse 14" cx="16.907" cy="16.907" rx="16.907" ry="16.907" transform="translate(2.093 2.093)" fill="none"/>
    <path id="Path_2923" data-name="Path 2923" d="M141.819,350.139a3,3,0,0,0-1.726-.678,2.487,2.487,0,0,0,1.48-.692,2.055,2.055,0,0,0,.531-1.488,2.177,2.177,0,0,0-.987-1.914,4.1,4.1,0,0,0-1.865-.622V343.58h-1.421V344.7h-.789V343.58h-1.424V344.7H134.49v9.781h1.129v1.105h1.424v-1.105h.789v1.105h1.423v-1.133a4.2,4.2,0,0,0,2.158-.719,2.392,2.392,0,0,0,1.013-2.033A2,2,0,0,0,141.819,350.139Zm-4.758-3.5H138.4a1.212,1.212,0,0,1,.831.266,1,1,0,0,1,0,1.427,1.215,1.215,0,0,1-.831.266h-1.341Zm2.41,5.616a1.311,1.311,0,0,1-.873.266h-1.538v-2.011H138.6a1.31,1.31,0,0,1,.873.266,1.029,1.029,0,0,1,0,1.481Z" transform="translate(-129.588 -330.717)" fill="#ff9415"/>
    <path id="Path_2924" data-name="Path 2924" d="M360.731,373.417a4.31,4.31,0,0,0-1.942-.417H354.68v9.781h2.571v-3.018h1.537a4.288,4.288,0,0,0,1.942-.419,3.1,3.1,0,0,0,1.3-1.181,3.676,3.676,0,0,0,0-3.565A3.1,3.1,0,0,0,360.731,373.417Zm-1.209,3.908a1.345,1.345,0,0,1-.971.341h-1.3V375.1h1.3a1.345,1.345,0,0,1,.971.342,1.429,1.429,0,0,1,0,1.889Z" transform="translate(-341.399 -359.021)" fill="#ff9415"/>
    <path id="Path_2925" data-name="Path 2925" d="M575.623,440.75h-.349a2.506,2.506,0,0,0-1.229.266,2.175,2.175,0,0,0-.8.8l-.2-1.062H570.83v7.21h2.417v-3.465q0-1.607,1.467-1.607h.908Z" transform="translate(-549.335 -424.197)" fill="#ff9415"/>
    <path id="Path_2926" data-name="Path 2926" d="M702,438.955a4.955,4.955,0,0,0-4.15,0,3.246,3.246,0,0,0-1.369,1.293,4.148,4.148,0,0,0,0,3.884,3.245,3.245,0,0,0,1.37,1.293,4.955,4.955,0,0,0,4.15,0,3.245,3.245,0,0,0,1.369-1.292,4.147,4.147,0,0,0,0-3.885A3.249,3.249,0,0,0,702,438.955Zm-1,4.513a1.441,1.441,0,0,1-2.152,0,2.284,2.284,0,0,1,0-2.557,1.441,1.441,0,0,1,2.152,0,2.284,2.284,0,0,1,0,2.557Z" transform="translate(-669.737 -422.033)" fill="#ff9415"/>
    <path id="Path_2927" data-name="Path 2927" d="M24.3,5.3a19,19,0,1,0,19,19A19,19,0,0,0,24.3,5.3ZM36.072,36.072a16.592,16.592,0,1,1,3.569-5.292,16.592,16.592,0,0,1-3.569,5.292Z" transform="translate(-5.3 -5.3)" fill="#ff9415"/>
  </g>
</g>
</svg>`;
