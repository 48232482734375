import { ethers } from 'ethers';
import { useWalletConnect } from './useWalletConnect';
export const useAccount = () => {
    var _a, _b, _c, _d, _e, _f;
    const { wallets } = useWalletConnect();
    const web3provider = ((_a = wallets[0]) === null || _a === void 0 ? void 0 : _a.provider)
        ? new ethers.providers.Web3Provider((_b = wallets[0]) === null || _b === void 0 ? void 0 : _b.provider)
        : undefined;
    const signer = web3provider === null || web3provider === void 0 ? void 0 : web3provider.getSigner();
    return {
        account: ((_d = (_c = wallets[0]) === null || _c === void 0 ? void 0 : _c.accounts[0]) === null || _d === void 0 ? void 0 : _d.address) || '',
        type: (_e = wallets[0]) === null || _e === void 0 ? void 0 : _e.label,
        eip1193Provider: (_f = wallets[0]) === null || _f === void 0 ? void 0 : _f.provider,
        provider: web3provider,
        signer,
    };
};
