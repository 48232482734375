var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
import React, { useEffect, useState, useCallback, useRef, } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Button, NotificationType, Overlay, Paragraph } from '@sovryn/ui';
import { CURRENT_RELEASE } from '../../../constants/general';
import { useNotificationContext } from '../../../contexts/NotificationContext';
import { translations } from '../../../locales/i18n';
import { register, unregister } from '../../../serviceWorkerRegistration';
import { getChangelogUrl } from '../../../utils/helpers';
const publicUrl = (_a = process.env.PUBLIC_URL) !== null && _a !== void 0 ? _a : window.location.origin;
const enableWorker = process.env.REACT_APP_ENABLE_SERVICE_WORKER === 'true';
//interval time to check for new release
const CHECK_TIME = 30e3; // 30 seconds
const releaseUrl = `${publicUrl}/release.json`;
export const ServiceWorkerProvider = ({ children }) => {
    const { addNotification } = useNotificationContext();
    const shownForCommit = useRef(`${CURRENT_RELEASE.commit}:${CURRENT_RELEASE.version}:${CURRENT_RELEASE.forcedCount}`);
    const [isForced, setIsForced] = useState(false);
    const [swRegistration, setSwRegistration] = useState();
    const updateHandler = useCallback(() => {
        if (swRegistration) {
            const waitingWorker = swRegistration && swRegistration.waiting;
            waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
        }
        else {
            unregister();
        }
        fetch(`/clear-site-data`).finally(() => window.location.replace(window.location.href));
    }, [swRegistration]);
    const showDialog = useCallback((state) => {
        const isForced = state.forcedCount > CURRENT_RELEASE.forcedCount;
        addNotification({
            id: 'app-update',
            title: t(translations.appUpdateDialog.title),
            content: (React.createElement(React.Fragment, null,
                React.createElement(Paragraph, { className: "mb-3" },
                    React.createElement(Trans, { i18nKey: translations.appUpdateDialog.changelog, components: [
                            React.createElement("a", { href: getChangelogUrl(CURRENT_RELEASE.commit), target: "_blank", rel: "noopener noreferrer" }, "-"),
                        ] })),
                React.createElement(Paragraph, { className: "mb-3" }, t(translations.appUpdateDialog.description)),
                React.createElement(Button, { onClick: updateHandler, text: t(translations.appUpdateDialog.updateButton) }))),
            dismissible: !isForced,
            type: isForced ? NotificationType.warning : NotificationType.info,
        }, isForced ? 604800000 : 30000);
    }, [addNotification, updateHandler]);
    const fetchVersion = useCallback(() => fetch(releaseUrl, {
        headers: {
            'Service-Worker': 'script',
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
        },
    })
        .then(response => response.json())
        .then(data => {
        const commitKey = `${data.commit}:${data.version}:${data.forcedCount}`;
        if ((data.commit !== CURRENT_RELEASE.commit ||
            data.version !== CURRENT_RELEASE.version ||
            data.forcedCount > CURRENT_RELEASE.forcedCount) &&
            shownForCommit.current !== commitKey) {
            if (swRegistration && swRegistration.update) {
                swRegistration.update();
            }
            else {
                shownForCommit.current = commitKey;
                showDialog(data);
                setIsForced(data.forcedCount > CURRENT_RELEASE.forcedCount);
            }
        }
        return data;
    })
        .catch(() => { }), [showDialog, swRegistration]);
    useEffect(() => {
        if (enableWorker && process.env.NODE_ENV === 'production') {
            fetchVersion();
            register({
                onUpdate: (registration) => __awaiter(void 0, void 0, void 0, function* () {
                    setSwRegistration(registration);
                    yield fetchVersion();
                }),
                onSuccess: (registration) => __awaiter(void 0, void 0, void 0, function* () {
                    setSwRegistration(registration);
                    yield fetchVersion();
                }),
            });
            const intId = setInterval(() => fetchVersion(), CHECK_TIME);
            return () => clearInterval(intId);
        }
        // eslint-disable-next-line
    }, []);
    return (React.createElement(React.Fragment, null,
        children,
        isForced && (React.createElement(Overlay, { portalTarget: "body", isOpen: true, children: React.createElement(React.Fragment, null) }))));
};
