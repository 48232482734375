import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { t } from 'i18next';
import { useOnClickOutside } from '@sovryn/ui';
import { ReactComponent as AppsIcon } from '../../../../../assets/images/apps-icon.svg';
import { translations } from '../../../../../locales/i18n';
import { getBitocracyUrl, getD1Url } from '../../../../../utils/helpers';
import { ProductLink } from '../ProductLink/ProductLink';
import styles from './ProductLinks.module.css';
const URI = getD1Url();
const linkToAlpha = (path) => `${URI}${path}`;
export const ProductLinks = () => {
    const [isOpen, setOpen] = useState(false);
    const dropdownRef = useRef(null);
    useOnClickOutside([dropdownRef], () => setOpen(false));
    const handleToggle = useCallback(() => setOpen(v => !v), []);
    return (React.createElement("li", { className: "lg:order-first relative" },
        React.createElement("button", { className: classNames(styles.button, {
                [styles.active]: isOpen,
            }), onClick: handleToggle },
            React.createElement(AppsIcon, { className: "w-6 h-6" })),
        React.createElement("div", { className: classNames(styles.dropdownOrList, {
                'lg:hidden': !isOpen,
            }), ref: dropdownRef },
            React.createElement("h3", { className: "px-2 py-4 lg:p-0" }, t(translations.productLinks.title)),
            React.createElement("ol", { className: styles.list },
                React.createElement(ProductLink, { href: linkToAlpha('/swap'), label: t(translations.productLinks.swap.title), description: t(translations.productLinks.swap.description) }),
                React.createElement(ProductLink, { href: linkToAlpha('/spot'), label: t(translations.productLinks.spot.title), description: t(translations.productLinks.spot.description) }),
                React.createElement(ProductLink, { href: linkToAlpha('/trade'), label: t(translations.productLinks.trade.title), description: t(translations.productLinks.trade.description) }),
                React.createElement(ProductLink, { href: linkToAlpha('/yield-farm'), label: t(translations.productLinks.yield.title), description: t(translations.productLinks.yield.description) }),
                React.createElement(ProductLink, { href: linkToAlpha('/lend'), label: t(translations.productLinks.lend.title), description: t(translations.productLinks.lend.description) }),
                React.createElement(ProductLink, { href: linkToAlpha('/borrow'), label: t(translations.productLinks.borrow.title), description: t(translations.productLinks.borrow.description) }),
                React.createElement(ProductLink, { href: linkToAlpha('/stake'), label: t(translations.productLinks.stake.title), description: t(translations.productLinks.stake.description) }),
                React.createElement(ProductLink, { href: getBitocracyUrl(), label: t(translations.productLinks.vote.title), description: t(translations.productLinks.vote.description) })))));
};
